/* 
===========================
  Base Settings - Spacing
===========================
*/

$sizes: (
  "": var(--space),
  --4xs: var(--space-4xs),
  --3xs: var(--space-3xs),
  --2xs: var(--space-2xs),
  --xs: var(--space-xs),
  --sm: var(--space-sm),
  --md: var(--space-md),
  --lg: var(--space-lg),
  --xl: var(--space-xl),
  --2xl: var(--space-2xl),
  --3xl: var(--space-3xl),
  --zero: 0
);

$sides: (
  "": "",
  --top: "-top",
  --bottom: "-bottom",
  --left: "-left",
  --right: "-right"
);

@each $size_key, $size_value in $sizes {
  @each $side_key, $side_value in $sides {
    .u-padding#{$size_key}#{$side_key} {
      padding#{$side_value}: #{$size_value};
    }

    .u-spacing#{$size_key}#{$side_key} {
      margin#{$side_value}: #{$size_value};
    }
  }
}


/* Examples */

/*
.u-padding--sm--top

translates to: 

padding-top: 10px;
@include media("medium") {
  padding-top: 15px;
}

.u-spacing--2xl--left

translates to:

margin-left: 40px;
@include media("medium") {
  margin-left: 80px;
}

.u-spacing--right

translates to:

margin-right: 10px;
@include media("medium") {
  margin-right: 20px;
}

*/
