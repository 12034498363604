
.new-account-page-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 840px;
    width: 694px;
    margin-top: 7vh;
    margin-bottom: 5vh;
    border-radius: 19px;
    background-color: #ffffff;
    box-shadow: 0px 6px 20px #0000008C;
}

.new-account-page-body-md {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 695px;
    width: 694px;
    margin-top: 15vh;
    margin-bottom: 5vh;
    border-radius: 19px;
    background-color: #ffffff;
    box-shadow: 0px 6px 20px #0000008C;
}

.new-account-page-body-sm {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 695px;
    width: 694px;
    margin-top: 15vh;
    margin-bottom: 5vh;
    border-radius: 19px;
    background-color: #ffffff;
    box-shadow: 0px 6px 20px #0000008C;
}

.new-account-login-logo {
    width: clamp(59px, (7.5vw + 7.5vh)/2, 123px);
    max-width: 123px;
    height: clamp(37px, (5vw + 5vh)/2, 80px);
}

.new-account-logo-typography {
    font-size:  clamp(19px, (3vw + 3vh)/2, 40px);
    font-weight: 550;
    text-align: center;
    font-family: 'Proxima Nova', sans-serif;
    color: black;
}

.new-account-header {
    width: 100%;
    height: 216px;
    background: #FBBC41;
    border-radius: 19px 19px 0px 0px;
}

.new-account-logo-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 216px;
    padding: 4%;
}

.new-account-title {
    font-size: clamp(16px, (3vh + 3vw)/2, 35px);
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

.new-account-title-full {
    margin: 7vh 0 5vh 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-account-subtitle {
    font-size: clamp(16px, (2vh + 2vw)/2, 24px);
    width: 70%;
    font-weight: normal;
    margin-top: 20px;
}

.new-account-subtitle-text {
    font-size: clamp(14px, (2vh + 2vw)/2, 24px);
    width: 70%;
    font-weight: normal;
    margin-top: 20px;
}

.new-account-text {
    font-size: clamp(10px, (2vh + 2vw)/2, 18px);
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
}

.new-account-subtext {
    font-size: clamp(10px, (2vh + 2vw)/2, 16px);
    margin-top: 10px;
    text-align: center;
}


.new-account-tip {
    font-size: clamp(10px, (2vh + 2vw)/2, 24px);
    margin-top: 10px;
}

.new-account-button-row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 50px;
    width: 70%;
}

.new-account-email-sent-text-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.new-account-email-sent-text {
    font-size: 36px;
    text-align: center;
    color: white;
}

.new-account-email-sent-text-top {
    margin-top: 12vh;
}

.new-account-email-sent-text-mid {
    margin-top: 5vh;
}

.new-account-email-sent-text-bottom {
    margin-bottom: 15vh;
}

.new-account-logo-lg {
    width: clamp(40px, (20vw + 20vh)/2, 160px);
    height: clamp(30px, (20vw + 20vh)/2, 100px);
    margin-top: 15vh;
}


@media (max-width: 1000px) {
    .new-account-page-body {
        height: 407px;
        width: 334px;
        margin-top: 2vh;
        margin-bottom: 5vh;
    }

    .new-account-page-body-md {
        height: 398px;
        width: 334px;
        margin-top: 2vh;
        margin-bottom: 5vh;
    }

    .new-account-page-body-sm {
        height: 322px;
        width: 334px;
        margin-top: 2vh;
        margin-bottom: 5vh;
    }

    .new-account-header {
        width: 100%;
        height: 100px;
        background: #FBBC41;
        border-radius: 19px 19px 0px 0px;
    }

    .new-account-button-row {
        width: 90%;
        margin-top: 0px;
    }

    .new-account-subtitle {
        width: 90%;
        font-weight: normal;
        margin-top: 20px;
        text-align: center;
    }

    .new-account-subtitle-text {
        width: 85%;
        margin-top: 20px;
        text-align: unset;
    }

    .new-account-tip {
        margin-top: 0px;
    }

    .new-account-title {
        font-size: 20px;
        margin-top: 5px;
    }

    .new-account-title-full {
        margin: 1vh 0 6vh 0;
    }

    .new-account-email-sent-text {
        font-size: 12px;
        text-align: center;
        color: white;
        max-width: 70vw !important;
    }

    .new-account-email-sent-text-top {
        font-size: 16px;
    }

    .new-account-email-sent-text-bottom {
        margin-bottom: 20vh;
    }
}


