/* 
===============================
  Base Settings - Breakpoints
===============================
*/


// Default
@mixin media($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);

  @if $value != null {
    @media (min-width: $value) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from '#{$breakpoint}'."
      + "Please make sure it is defined in '$breakpoints' map";
  }
}

/* Example:
@include media("medium") {}
*/

// Maximum Width
@mixin media-max($breakpoint) {

  $value: map-get($breakpoints, $breakpoint);

  @if $value != null {
    @media (max-width: ($value - 1)) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from '#{$breakpoint}'."
      + "Please make sure it is defined in '$breakpoints' map";
  }
}

/* Example:
@include media-max("medium") {}
*/


// Small Desktop Height
@mixin media-height() {
  @media only screen and (min-width: 768px) and (max-height: 730px) {
    @content;
  }
}

@mixin media-height-lg() {
  @media only screen and (min-width: 768px) and (max-height: 830px) {
    @content;
  }
}

/* Example:
@include media-height() {}
*/
