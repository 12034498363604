.privacyterms-back-button-div {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.privacyterms__title {
    display: flex;
    height: 44px;
    padding-bottom: var(--space);
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex-grow: 1;
    margin-bottom: var(--space-xl);
}

.privacyterms-login-text {
    color: black;
    font-size: clamp(8px, (3.5vh + 3.5vw)/2, 25px);
    margin-right: 40px;
    font-weight: bold;
    cursor: pointer;
}
