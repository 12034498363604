@import "../../../../css/design-system/breakpoints";
@import "../../../../css/design-system/main";

.c-nav-secondary {
    display: flex;
    margin-bottom: var(--space-xl);
    margin-top: var(--space-xl);
}

.c-nav-secondary__menu {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    list-style-type: none;
    padding-left: 0;
    @include media-max("medium") {
        gap: var(--space-2xs);
        flex-direction: column;
    }
}
a.c-nav-secondary__text {
    font-size: var(--font-size-18);
    color: var(--color-neutral-100) !important;
    cursor: pointer;
}

a.c-nav-secondary__text.active {
    color: var(--color-blue-50) !important;
}

a.c-nav-secondary__text.disabled {
    opacity: .5;
    color: var(--color-neutral-100);
    cursor: default;
    pointer-events: none;
}

a.c-nav-secondary__text:hover {
    color: var(--color-blue-50);
}