.deactivate-device__form {
    width: 100%;
}

.deactivate-device__form__root {
    display: flex;
    margin-top: 2%;
    flex-grow: 1;
    max-width: 70vw;
}

.deactivate-device__form__title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    margin-bottom: 5%;
}

.deactivate-device__form__title-text {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--font-size-32);
    font-weight: bold;
    color: var(--primary-color);
    opacity: 1;
}

.deactivate-device__stats-div {
    display: flex;
    flex-direction: column;
    max-width: 850px;
    padding: 20px;
    border-radius: 10px;
    background-color: #F5DBA3;
    margin-bottom: 3vh;
}

.deactivate-device__input__root {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.deactivate-device__input__label {
    position: initial;
    color: black;
}

.deactivate-device__input__text {
    padding: 1vh 0 0 1vh;
    margin-top: 8px;
}

.deactivate-device__inactive-reason {
    display: flex;
    width: 100%;
}

.deactivate-device__reason-detail {
    display: flex;
    align-items: center;
}

.deactivate-device__reason-detail__label {
    font-size: var(--font-size-18);
}

.deactivate-device__reason-detail__text {
    border: 2px solid var(--primary-color);
    border-radius: 11px;
    margin: 0 0 20px;
}

.deactivate-device__reason-detail__text--disabled {
    border: 2px solid var(--primary-color);
    border-radius: 11px;
    padding-left: 10px;
}

.deactivate-device__reason-detail__required-text {
    font-size: var(--font-size-18);
    opacity: 0.5;
}

.deactivate-device__top-actions {
    display: flex;
    width: 50%;
    justify-content: space-evenly;
}

.deactivate-device__button-div {
    display: flex;
    margin-top: 5vh;
    max-width: 500px;
    justify-content: flex-start;
}

@media (max-width: 960px) {
    .deactivate-device__button-div {
        max-width: unset;
    }
}