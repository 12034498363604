.loading-button {
  margin-left: 10%;
  color: white;
  background-color: #b36762;
  border-radius: 15px;
  font-size: min(1.15vw, 1.15vh)
}

/* ButtonDiv */
.button-div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 0 0 10% 0;
  height: clamp(15px, (5vh + 5vw)/2, 60px);
}

.buttons {
  height: 65%;
  max-height: 60px;
  border-radius: 30px;
  border-width: 0;
  margin-bottom: 5px;
}

.button-cancel {
  font-family: "Proxima Nova", sans-serif;
  font-weight: 500;
  font-size: min(2.25vw, 2.55vh);
  color: #FFFFFF;
  background: #444444 0 0 no-repeat padding-box;
  letter-spacing: 0;
  opacity: 1;
  width: 60%;
  margin-right: 5%;
}

.button-submit {
  font-family: "Proxima Nova", sans-serif;
  font-weight: 500;
  font-size: min(2.25vw, 2.55vh);
  color: #000000;
  background: #FBBC41 0 0 no-repeat padding-box;
  letter-spacing: 0;
  opacity: 1;
  width: 100%;
}

/* InputForm */
.forgot-input-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* InputFormControl */
.forgot-input-form-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

/* InputRoot */
.forgot-input-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10% 0 10%;
  width: 100%;
  height: 100%;
}

/* Input */
.forgot-input {
  font-size: clamp(10px, (2vh + 2vw)/2, 18px);
  margin: 5%;
  width: 80%;
  height: 100%;
  border-radius: 11px;
  background-color: #F0F0F0;
}

.forgot-input-div-row-form-control {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15%;
  max-height: 60px;
  width: 100%;
  margin-bottom: 10%;
}

/* LargeHelpText */
.large-help-text {
  font-family: "Proxima Nova", sans-serif;
  font-weight: normal;
  font-size: clamp(6px, (1.5vh + 1.5vw)/2, 18px);
  text-align: center;
  margin: 0 0 10% 0;
}

.forgot-password-title {
  font-family: "Proxima Nova", sans-serif;
  font-size: clamp(12px, (2.5vh + 2.5vw)/2, 30px);
  width: 100%;
  text-align: center;
  color: #272727;
  font-weight: 550;
  margin-bottom: 5%;
  margin-top: 10%;
}

/* HelpText */
.help-text {
  text-align: center;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: min(1.75vw, 1.75vh);
  width: 80vw;
}
