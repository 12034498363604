.progress-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.label {
    font-size: 16px;
    margin-bottom: 5px;
}

.progress-bar {
    display: flex;
    align-items: center;
    border-radius: 15px;
    width: 100%;
    height: 20px;
    border: 1px solid #ccc;
    position: relative;
}

.bar {
    height: 100%;
    border-radius: 15px;
}

.triangle {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 7px solid #000; /* You can adjust the color */
    transform: translateY(120%);
}

.value {
    position: absolute;
    bottom: 0;
    top: 25px;
    margin-left: -4px;
    text-align: center;
    width: 25px;
    font-size: 14px;
    font-weight: bold;
}

.maxValue {
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
}