.tabs {
    border: 1px solid #ccc;
    border-radius: 5px;
}

.tab-list {
    display: flex;
}

.tab {
    color: var(--emergency-primary-color);
    min-width: 90px;
    font-size: clamp(8px, (1.8vw + 1.8vh)/2, 18px);
    font-weight: bold;
    cursor: pointer;
    padding: 0 0 0 10px;
    border: 2px solid var(--emergency-primary-color);
    border-bottom: none;
    margin-right: 10px;
    transition: background-color 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    max-width: 300px;
}

.tab.active {
    background-color: var(--emergency-primary-color);
    color: white;
}

.tab-content {
    background-color: white;
    border-top: 2px solid var(--emergency-primary-color);
    border-radius: 0 0 5px 5px;
    padding-left: 10px;
    padding-top: 10px;
}

.tab-image-div {
    position: relative;
    width: 50px;
    margin-left: 20px;
    height: 45px;
    padding-top: 5px;
}

.tab-image {
    width: 100%;
    height: 100%;
}

.tab-content-image {
    width: clamp(200px, (50vw + 50vh)/2, 430px);
    height: clamp(180px, (42vw + 42vh)/2, 380px);
}
