/* 
===========================
  Base Settings - Display
===========================
*/

.u-display--inline-block {
  display: inline-block;
}

.u-display--block {
  display: block;
}

.u-display--flex {
  display: flex;
}

.u-display--flex-row {
  flex-direction: row;
}

.u-display--flex-column {
  flex-direction: column;
}

.u-justify-content--space-between {
  justify-content: space-between;
}

.u-justify-content--center {
  justify-content: center;
}

.u-align-items--center {
  align-items: center;
}

.u-align-items--base {
  align-items: baseline;
}

.u-align-items--end {
  align-items: flex-end;
}

.u-justify-content--flex-start {
  justify-content: flex-start;
}

.u-justify-content--flex-end {
  justify-content: flex-end;
}

.u-align-items--start {
  align-items: flex-start;
}

.u-flex-directon--column {
  flex-direction: column;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-align--center {
  text-align: center;
}

.u-align--right {
  text-align: right;
}

.u-icon-sm {
  width: 16px;
  height: 16px;
}

.u-icon-md {
  width: 22px;
  height: 22px;
}

.u-margin-unset {
  margin: 0;
  padding: 0;
}

.u-space-sm {
  margin: var(--space);
}

.u-full-width {
  width: 100%;
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-gap-xs {
  gap: var(--space-2xs);
}

.u-gap-sm {
  gap: var(--space-sm);
}

.u-gap {
  gap: var(--space)
}

.u-space-right {
  margin-right: var(--space);
}

.u-letter-space-2xs {
  letter-spacing: 5px;
}

.u-hide-after--sm {
  @include media("small") {
    display: none;
  }
}

.u-hide-before--sm {
  @include media-max("small") {
    display: none;
  }
}

.u-hide-after--m {
  @include media("medium") {
    display: none;
  }
}

.u-hide-before--m {
  @include media-max("medium") {
    display: none;
  }
}

.u-hide-after--l {
  @include media("large") {
    display: none;
  }
}

.u-hide-before--l {
  @include media-max("large") {
    display: none;
  }
}
