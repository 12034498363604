.o-search-select {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--space-3xs);
}

.o-search-select__container {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    gap: var(--space-3xs);
}

.o-search-select__text {
    font: var(--font-size-14);
    font-weight: 400;
    line-height: 20px;
    color: var(--color-neutral-90);
    padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-sm);
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
}

.o-search-select__dropdown {
    position: absolute;
    top: calc(100% + var(--space-2xs));
    left: 0;
    right: 0;
    width: 100%;
    max-height: 0;
    padding: var(--space-xs) 0 var(--space-xs) 0;
    border: 2px solid var(--color-neutral-20);
    opacity: 0;
    border-radius: var(--space-2xs);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
    overflow: scroll;
    overflow: -moz-scrollbars-none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
    overflow-x: hidden;
    pointer-events: none;
    background-color: var(--color-neutral-00);
    transition: all 0.4s ease-in-out;
}

.o-search-select__dropdown.active {
    max-height: 115px;
    opacity: 1;
    pointer-events: all;
}

.o-search-select__dropdown::-webkit-scrollbar {
    display: none; /* For Webkit browsers */
}

.o-search-select__dropdown__option {
  font-size: var(--font-size-14);
  line-height: 1.4;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 var(--space) 0 var(--space-sm);
}

.o-search-select__dropdown__option:hover {
  background-color: var(--color-blue-20);
}