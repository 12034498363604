.createexistingdevice-modal {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 2px solid orange;
    background-color: white;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-48%, -50%);
    z-index: 2;
    min-width: 300px;
}

.createexistingdevice-text-div {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 24px;
    margin-left: 24px;
    margin-right: 24px;
}

.createexistingdevice-text-div-left {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 12px;
    margin-right: 24px;
}

.createexistingdevice-title-text {
    font-size: clamp(8px, (2vw + 2vh)/2, 20px);
}

.createexistingdevice-button-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.createexistingdevice-cancel-div {
    display: flex;
    justify-content: flex-end;
}

.createexistingdevice-devicedata-div {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    margin-bottom: 30px;
}

.createexistingdevice-devicedata-text {
    display: inline-block;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: bold;
    border-radius: 5px;
}

.createexistingdevice-comparedevice-div {
    display: flex;
    flex-direction: row;
    min-width: 300px;
}

.createexistingdevice-comparedevice-data-div {
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    padding-left: 24px;
    width: 45%;
    max-height: 450px;
    padding-bottom: 24px;
    padding-top: 12px;
}

.createexistingdevice-comparedevice-data-div-short {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #f6f6f6;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    padding: 24px 24px 40px;
    width: 100%;
}

.createexistingdevice-comparedevice-title {
    text-align: left;
    font-size: clamp(8px, (2.5vh + 2.5vw)/2, 28px);
    font-weight: 700;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--primary-color);
    opacity: 1;
    margin-bottom: 15px;
}

.createexistingdevice-comparedevice-data-title {
    text-align: left;
    font-size: clamp(8px, (2.2vh + 2.2vw)/2, 24px);
    font-weight: 500;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--primary-color);
    opacity: 1;
}

.createexistingdevice-comparedevice-data {
    text-align: left;
    font-size: clamp(8px, (2.5vh + 2.5vw)/2, 28px);
    font-weight: 500;
    font-family: 'Proxima Nova', sans-serif;
    opacity: 1;
}
