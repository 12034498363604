@import "../../../../css/design-system/main";
@import "../../../../css/design-system/breakpoints";

.devices__input__root {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    flex-direction: row;
    margin: 0 0 10px 0;
    padding: 0;
    @include media-max("medium") {
        justify-content: center;
        max-width: unset;
    }
}

.devices__input__div {
    display: flex;
    align-items: center;
    padding-right: 1.3vw;
}

.devices__top-actions {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;
    flex-direction: row;
}

.device-table-body {
    display: flex !important;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
}

.device-grid-table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    gap: 2px;
}

.device-header {
    background-color: transparent;
}

.device-row {
    display: grid;
    align-items: center;
    justify-items: start;
    height: 65px;
    border-bottom: 1px solid grey;
}

.device-cell {
    padding: 1%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(10px, (1.5vh + 1.5vw)/2, 18px);
}

.device-status-cell {
    padding: 1%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(10px, (1.5vh + 1.5vw)/2, 18px);
    min-width: 220px;
}

.device-header-div {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: start;
}

.device-body-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    height: 50vh;
}

.device-loading-div {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}

.device-to-top-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 3px solid;
    justify-content: space-between;
    color: BLACK;
    font-weight: bold;
    font-size: clamp(8px, (3vw + 3vh)/2, 24px);
    max-height: 55px;
    min-height: 40px;
    border-color: var(--secondary-color);
    border-radius: 10px;
    text-transform: none;
    margin-top: 10px;
    padding-left: 8px;
    padding-right: 8px;
    max-width: 150px;
}

@media (max-width: 1280px) {
    .device-status-cell {
        min-width: unset;
    }
}
