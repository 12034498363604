.createdevice-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.createdevice-server-message-div {
    display: flex;
    flex-direction: column;
}

.createdevice-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    margin-bottom: 30px;
}

.createdevice-title {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    text-align: left;
    font-size: clamp(24px, (3vh + 3vw)/2, 32px);
    font-weight: 500;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--primary-color);
    opacity: 1;
}

.createdevice-confirm-title {
    text-align: left;
    font-size: clamp(24px, (3vh + 3vw)/2, 32px);
    font-weight: 500;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--primary-color);
    opacity: 1;
    margin-bottom: 30px;
}

.createdevice-device-id-div {
    border-bottom: 2px solid #BFBFBF;
    padding-bottom: 15px
}

.createdevice-device-id-note-div {
    margin-top: 10px;
    white-space: nowrap;
}

@media (max-width: 600px) {
    .createdevice-device-id-note-div {
        margin-top: 10px;
        white-space: normal;
    }
}

.createdevice-device-id-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin: 5% 0 1% 0;
}

.createdevice-device-id-title {
    text-align: left;
    font-size: clamp(18px, (2.5vh + 2.5vw)/2, 24px);
    font-weight: 500;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--primary-color);
    opacity: 1;
}

.createdevice-device-id-textfield {
    margin-bottom: 4% !important;
    width: 40%;
    border-radius: 5px;
    border: 1px solid black;
    font-size: clamp(16px, (2vh + 2vw)/2, 18px);
    font-weight: normal;
    font-family: 'Proxima Nova', sans-serif;
    color: black;
    padding: 3px 3px 3px 15px;
}

.createdevice-other-div {
    
}

.createdevice-other-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin: 5% 0 1% 0;
}

.createdevice-other-title {
    text-align: left;
    font-size: clamp(18px, (2.5vh + 2.5vw)/2, 24px);
    font-weight: 700;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--primary-color);
    opacity: 1;
}

.createdevice-device-tag-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin: 5% 0 1% 0;
}

.createdevice-device-tag-title {
    text-align: left;
    font-size: clamp(16px, (2vh + 2vw)/2, 18px);
    font-weight: 500;
    font-family: 'Proxima Nova', sans-serif;
    opacity: 1;
}

.createdevice-device-tag-textfield {
    
}

.createdevice-device-type-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin: 5% 0 1% 0;
}

.createdevice-device-type-title {
    text-align: left;
    font-size: clamp(16px, (2vh + 2vw)/2, 18px);
    font-weight: 500;
    font-family: 'Proxima Nova', sans-serif;
    opacity: 1;
}

.createdevice-device-type-select {
    display: flex;
    width: 100%;
    font-size: clamp(16px, (2vh + 2vw)/2, 18px);
    margin-bottom: 2% !important;
    cursor: pointer;
}

.createdevice-device-type-select:focus {
    outline: none;
}

.createdevice-device-id-note-bold {
    color: var(--primary-color);
    font-size: clamp(16px, (2vw + 2vh)/2, 18px);
    font-weight: bold;
}

.createdevice-device-id-note {
    font-size: clamp(16px, (2vw + 2vh)/2, 18px);
}

.createdevice-red-star {
    color: red;
}

.createdevice-save-button-div {
    display: flex;
    width: 100%;
    max-width: 800px;
    margin-top: 30px;
    justify-content: flex-start;
}
