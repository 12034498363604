/* Carousel Container */
.carousel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    max-width: 1280px; /* Adjust the max-width as needed */
    margin: 0 auto;
    padding: 40px;
    background-color: #f0f0f0;
}

/* Carousel Title */
.carousel-title {
    font-size: max(min((4vh + 4vw)/2, 40px), 14px);
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
}

/* Carousel Content (including Image and Arrows) */
.carousel-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Carousel Image */
.carousel-image {
    display: flex;
    width: 90%;
    height: auto;
    border-radius: 8px;
}

/* Carousel Arrows */
.carousel-left-arrow {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
}
.carousel-right-arrow {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
}

.carousel-arrow-div {
    width: 40px;
    height: 40px;
    margin-top: 8px;
}

.carousel-dots-div {
    display: flex;
    justify-content: center;
}

/* Carousel Dots */
.carousel-dots {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.carousel-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid;
    margin: 0 10px;
    cursor: pointer;
}

.carousel-dot.active {
    background-color: #000;
}

/* Carousel Close Button */
.carousel-close-button-first {
    width: 80px;
    height: 32px;
    color: lightskyblue;
    font-size: 18px;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.carousel-close-button {
    width: 80px;
    height: 32px;
    color: #fff;
    font-size: 18px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 15px;
}

.carousel-close-button-div {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
