.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8vh;
    min-height: 40px;
    max-width: 100%;
    box-shadow: none;
    background: transparent;
    width: 100%;
}

.footer-content {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 7vh;
    justify-content: space-between;
}

.footer-text {
    color: black;
    font-weight: 500;
}

.footer-privacy {
    justify-content: flex-end;
    display: flex;
    margin-right: 10px;
}

.footer-privacy:hover {
  cursor: pointer;
}

.footer-terms {
    justify-content: flex-start;
    display: flex;
    margin-left: 10px;
}

.footer-terms-privacy-text {
    display: flex;
    font-family: "Proxima Nova", sans-serif;
    font-weight: bold;
    font-size: clamp(8px, (1.5vw + 1.5vh)/2, 18px);
    letter-spacing: 0px;
    color: black;
    opacity: 1;
    border-bottom: 1px solid;
    padding-bottom: 1px;
}

.footer-terms:hover {
    cursor: pointer;
}

.footer-terms-privacy-div {
  display: flex;
  text-align: center;
  align-items: baseline;
  justify-content: space-evenly;
}
