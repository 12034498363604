.dialog-alert-container {
    width: 40vw;
    height: 32vh;
}

.dialog-alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 570px;
    height: fit-content;
    max-height: 350px;
    margin: 1vw;
}

.dialog-alert-title {
    font-weight: 550;
    text-align: center;
    padding: 24px;
    font-size: clamp(10px, (2vh + 2vw)/2, 28px);
}

.dialog-alert-title-error {
    font-weight: 550;
    text-align: center;
    padding: 24px;
    font-size: clamp(10px, (2vh + 2vw)/2, 28px);
    color: red;
}

.dialog-alert-message {
    display: flex;
    align-items: center;
    text-align: center;
    color: black;
    margin-bottom: 25px;
    margin-right: 64px;
    margin-left: 64px;
    font-size: clamp(10px, (2vh + 2vw)/2, 22px);
}

.dialog-alert-button {
    border-radius: 5px;
    width: 10vw;
    max-width: 130px;
    min-width: 75px;
    max-height: 50px;
    margin-bottom: 20px;
    color: white;
    font-size: clamp(12px, (1.3vh + 1.3vw)/2, 14px);
    text-align: center;
    padding: 8px;
    border: none;
    box-shadow: 0px 0px 6px #999999;
    cursor: pointer;
}

.dialog-alert-button.cancel {
    background-color: black;
    margin-left: 16px;
}

.dialog-alert-button.confirm {
    background-color: var(--primary-color);
    margin-right: 16px;
}

.dialog-input-error {
    color: var(--error-color);
}
