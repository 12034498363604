/* 
=========================
  Theme Settings - Text
=========================
*/

body {
  font-family: var(--font-family);
  font-size: var(--responsive-size--xs);
  line-height: 1.4;
  font-weight: 400;
  color: var(--color-neutral-90);
}

a {
  line-height: 24px;
  font-weight: 500;
  color: var(--color-blue-50);
  text-decoration: none;
  gap: var(--space-xs);
  border-radius: var(--space-2xs);
  border: 2px solid transparent;
  transition: all 0.4s ease-out;
}

a:active {
  color: var(--color-blue-50);
  border: 2px solid var(--color-yellow-50);
}

a:hover {
  color: var(--color-blue-60);
}

.o-rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    margin-top: calc(var(--space) + 5px);
    margin-bottom: calc(var(--space) + 5px);
  }
  
  > * {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

/*
  ================
    Font Weights
  ================
*/

/* Default */
//strong {
//  font-weight: 700;
//}

/* Regular */
.u-font-weight--400 {
  font-weight: 400;
}

/* Semibold */
.u-font-weight--500 {
  font-weight: 500;
}

/* Bold */
.u-font-weight--700 {
  font-weight: 700;
}

/*
  ================
    Heading Text
  ================
*/

.o-heading--xl {
  font-size: var(--responsive-size--xl);
  line-height: 1.2;
  font-weight: 500;
  color: var(--color-neutral-100);
}

h1,
.o-heading--l {
  font-size: var(--responsive-size--l);
  line-height: var(--responsive-lh-l);
  font-weight: 500;
  color: var(--color-neutral-100);
}

h2,
.o-heading--m {
  font-size: var(--responsive-size--m);
  line-height: var(--responsive-lh-m);
  font-weight: 500;
  color: var(--color-neutral-100);
}

h3,
.o-heading--s {
  font-size: var(--responsive-size--s);
  line-height: var(--responsive-lh-s);
  font-weight: var(--responsive-fw-bold-semi);
  color: var(--color-neutral-100);
}

h4,
h5,
.o-heading--xs {
  font-size: var(--responsive-size--xs);
  line-height: 1.4;
  font-weight: 500;
  color: var(--color-neutral-100);
}

h6 {
  font-size: var(--font-size-16);
  font-weight: 500;
  line-height: 1.4;
  color: var(--color-neutral-100);
}


/*
  =============
    Body Text
  =============
*/
p,
li,
.o-body-text {
  font-size: var(--responsive-size--xs);
  line-height: 1.4;
  font-weight: 400;
}

.o-body-text--l {
  font-size: var(--responsive-size--s);
  line-height: var(--responsive-lh-s);
  font-weight: 400;
}

.o-body-text--m {
  font-size: var(--font-size-16);
  line-height: 1.4;
  font-weight: 400;
}

.o-body-text--s {
  font-size: var(--font-size-14);
  line-height: 1.4;
  font-weight: 400;
}

.o-body-text--xs {
  font-size: var(--font-size-12);
  line-height: 1.4;
  font-weight: 400;
}
