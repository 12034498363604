@import "../../../../css/design-system/main";
@import "../../../../css/design-system/breakpoints";

.o-text-identifier-input__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
}

.o-text-identifier-input {
  border: 2px solid var(--color-neutral-100);
  border-radius: 5px;
  padding: var(--space-sm) var(--space) var(--space-sm) var(--space);
  transition: all 0.4s ease-out;
  width: 100%;
  font-weight: 400;
  color: var(--color-neutral-90);
}

.o-text-identifier-input__label {
  color: var(--color-neutral-100);
}

.o-text-identifier-input:active {
  outline: none; /* Remove default outline */
  border-color: var(--color-yellow-50);
}

.o-text-identifier-input:focus {
  outline: none; /* Remove default outline */
  border-color: var(--color-yellow-50);
}

.o-text-identifier-input:disabled {
  opacity: 0.3;
  border-color: var(--color-neutral-100) !important;
}

.o-text-identifier-input--sm {
  padding: var(--space-2xs) var(--space-xs) var(--space-2xs) var(--space-xs);
}

.o-text-identifier-input--md {
  @include media-max("medium") {
    padding: var(--space-2xs) var(--space-xs) var(--space-2xs) var(--space-xs);
  }
  padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-sm);
}

.o-text-identifier-input--lg {
  @include media-max("medium") {
    padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-sm);
  }
  padding: var(--space-sm) var(--space) var(--space-sm) var(--space);
}

.o-text-identifier-input__error {
  display: flex;
  font-size: var(--font-size-14);
  line-height: 20px;
  gap: 10px;
  color: var(--color-red-50);
}