.reactivate-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.reactivate-root-div {
    display: flex;
    margin-top: 2%;
    margin-bottom: 3vw;
    width: 90vw;
    max-height: 750px;
    max-width: 540px;
}
.reactivate-main-body-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    float: left;
    width: 90vw;
    height: 100%;
    min-width: unset;
    max-width: unset;
}

.reactivate-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    margin-bottom: 5%;
}

.reactivate-title {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    text-align: left;
    font-weight: bold;
    font-family: "Proxima Nova", sans-serif;
    font-size: clamp(12px, (4vh + 4vw)/2, 30px);
    color: #517D9E;
    opacity: 1;
}

.reactivate-restore-title {
    text-align: left;
    font-family: "Proxima Nova", sans-serif;
    font-weight: 700;
    font-size: clamp(8px, (2vh + 2vw)/2, 16px);
    color: #000000;
    opacity: 1;
}

.reactivate-details-title {
    text-align: left;
    font-family: "Proxima Nova", sans-serif;
    font-weight: 700;
    font-size: clamp(10px, (3vh + 3vw)/2, 20px);
    color: #517D9E;
    opacity: 1;
    margin: 5% 0 5% 0;
}

.reactivate-deactivation-body {
    background: #F0F0F0 0 0 no-repeat padding-box;
    border-radius: 5px;
    width: 80%;
    padding: 3%;
}

.reactivate-deactivation-reason-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 2px solid #AEAEAE;
    padding-bottom: 5%;
    margin-bottom: 5%;
}

.reactivate-deactivation-reason-titles {
    font-family: "Proxima Nova", sans-serif;
    font-weight: 700;
    font-size: clamp(8px, (2vh + 2vw)/2, 16px);
    color: #000000;
    opacity: 1;
    margin-bottom: 5%;
}

.reactivate-deactivation-reason-description {
    font-family: "Proxima Nova", sans-serif;
    font-weight: 500;
    font-size: clamp(8px, (2vh + 2vw)/2, 16px);
    color: #000000;
    opacity: 1;
}

.reactivate-deactivation-notes-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 2px solid #AEAEAE;
    padding-bottom: 5%;
    margin-bottom: 5%;
}

.reactivate-deactivation-notes-titles {
    font-family: "Proxima Nova", sans-serif;
    font-weight: 700;
    font-size: clamp(8px, (2vh + 2vw)/2, 16px);
    color: #000000;
    opacity: 1;
    margin-bottom: 5%;
}

.reactivate-deactivation-notes-description {
    font-family: "Proxima Nova", sans-serif;
    font-weight: 500;
    font-size: clamp(6px, (1.5vh + 1.5vw)/2, 14px);
    color: #000000;
    opacity: 1;
}

.reactivate-deactivation-data-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.reactivate-deactivation-data-titles {
    font-family: "Proxima Nova", sans-serif;
    font-weight: 700;
    font-size: clamp(8px, (2vh + 2vw)/2, 16px);
    color: #000000;
    opacity: 1;
}

.reactivate-deactivation-data-description {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    font-family: "Proxima Nova", sans-serif;
    font-weight: 500;
    font-size: clamp(8px, (2vh + 2vw)/2, 16px);
    color: #000000;
    opacity: 1;
}

.reactivate-details-body {
    display: flex;
    flex-direction: column;
    max-height: 70px;
    width: 40%;
}

.reactivate-details-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5%;
}

.reactivate-details-row-title {
    font-family: "Proxima Nova", sans-serif;
    font-weight: normal;
    font-size: clamp(8px, (2vh + 2vw)/2, 16px);
    margin-bottom: 2%;
}

.reactivate-details-row-data {
    padding: 2% 2% 2% 5%;
    background: #F0F0F0 0 0 no-repeat padding-box;
    border-radius: 5px;
    color: #808080;
    font-family: "Proxima Nova", sans-serif;
    font-weight: normal;
    font-size: clamp(6px, (1.5vh + 1.5vw)/2, 14px);
    margin-bottom: 2%;
}

.reactivate-body {
    display: flex;
    align-content: center;
    justify-content: flex-start;
}

.reactivate-button-div {
    display: flex;
    width: 100%;
    margin: 3% 0 0 0;
}
