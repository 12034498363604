
.request-section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.request-label {
    margin: 0 10px 0 50px;
    font-weight: bold;
    font-size: 18px;
}

.request-field-row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 3vh;
}

.request-tooltip-icon {
    font-size: clamp(10px, (2vh + 2vw) / 2, 20px);
    padding-left: 5px;
    width: .8em !important;
    height: .8em !important;
    color: var(--primary-color);
}

.request-field-input-label {
    display: flex;
    min-width: 200px;
    font-size: 1.2rem;
    position: initial;
    font-weight: 500;
}

.request-field-input-div {
    width: 30vw;
    display: flex;
    max-width: 450px;
    min-width: 150px;
}

.request-field-input {
    margin: 2vh 0 0 1vh;
    border: 2px solid;
    border-radius: 11px;
    width: 30vw;
    max-width: 300px;
    min-width: 150px;
    padding-left: 10px;
}

.request-field-input-notes {
    margin: 2vh 0 0 1vh;
    border: 2px solid;
    border-radius: 11px;
    border-color: black;
    width: 30vw;
    max-width: 600px;
    min-width: 200px;
    padding-left: 10px;
}

.request-button-div {
    display: flex;
    margin-top: 5vh;
    width: 100%;
    justify-content: flex-start;
}

.request-input-select {
    width: 60vw;
    cursor: pointer;
    padding: 6px 0px 7px;
    max-width: 600px;
    min-width: 150px;
    display: flex;
    justify-content: space-between;
}

.request-input-select-sm {
    width: 20vw;
    cursor: pointer;
    margin: 2vh 0px 0px;
    padding: 6px 0px 7px;
    max-width: 250px;
    min-width: 150px;
    display: flex;
    justify-content: space-between;
}

@media (max-width:600px) {
    .request-field-input-label {
        min-width: 70px;
        max-width: 70px;
        font-size: .9em;
    }
    .request-label {
        margin: 0 10px 0 10px;
        font-weight: bold;
        font-size: 12px;
    }
    .request-field-input-div {
        width: 80vw;
    }
    .request-field-input {
        width: 80vw;
        max-width: 300px;
        min-width: 150px
    }
    .request-button-div {
        max-width: unset;
    }
    .request-input-select {
        width: 80vw;
        max-width: 450px;
        min-width: 150px;
        margin: 2vh 0 0 1vh;
    }
    .request-input-select-sm {
        width: 40vw;
        max-width: 250px;
        min-width: 150px;
        margin: 2vh 0 0 1vh;
    }
}
