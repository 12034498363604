body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}
html {
  margin: 0;
  padding: 0;
  height: 100%;
}
html *
{
  font-family: "Proxima Nova", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

option {
  -webkit-align-content: center;
  -webkit-justify-content: center;
}
#root {
  height: 100vh;
}

#leftnav-link:hover {
  background-color: orange;
}

::-webkit-file-upload-button {
  display: none;
}

::file-selector-button {
  display: none;
}

.select-option {
  color:black
}

.full-width {
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.back-button {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 0 5% 0 0  !important;
  height: min(4.5vh, 4.5vw) !important;
}

.back-button-arrow {
  color: #000000;
  font-size: clamp(12px, (4.5vh + 4.5vw)/2, 40px) !important;
}

.variable-sized-list {
  height: 55px;
}

.variable-sized-list::-webkit-scrollbar {
  width: 10px; /* Adjust scrollbar width as needed */
}

.variable-sized-list::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

.variable-sized-list::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 5px;
}

.variable-sized-list::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

.infinite-scroll-auto-sizer {
}
