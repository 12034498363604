@import "../../../../css/design-system/main";

.o-table {
  border-spacing: 0;
  border-radius: 5px;
  border: 1px solid var(--color-neutral-20);
  width: 100%;
  table-layout: fixed;
  background-color: var(--color-neutral-00);
}

.o-table tr:last-child td {
  border-bottom: none;
}

.o-table th, .o-table td {
  border-bottom: 1px solid var(--color-neutral-20);
  text-align: left;
}

.o-table__cell {
  font-size: var(--font-size-16);
  line-height: 1.4;
  font-weight: 400;
  padding: 20px;
  display: table-cell;
  vertical-align: middle;
  word-break: break-word;
}

.o-table__cell--header {
  font-size: var(--font-size-16);
  color: var(--color-neutral-100);
  line-height: 1.4;
  font-weight: 500;
  margin-right: 20px;
}

/* Card layout for smaller screens d*/
@media screen and (max-width: $breakpoint-md) {
  .o-table, .o-table thead, .o-table tbody, .o-table th, .o-table td, .o-table tr {
    width: 100%;
  }

  .o-table {
    border: none;
    background-color: transparent;
  }

  .o-table tbody {
    gap: 20px;
  }

  .o-table tr {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 1rem;
    border: 1px solid var(--color-neutral-20);
    border-radius: 5px;
    background-color: var(--color-neutral-00);
    padding: 10px 15px 10px 15px;
  }

  .o-table tr:last-child td {
    border-bottom: 1px solid var(--color-neutral-20);
  }

  .o-table tr td:last-child {
    border-bottom: none;
  }

  .o-table td::before {
    content: attr(data-label);
    font-weight: 500;
    flex: 0 0 50%;
  }

  .o-table td:last-child {
    border-bottom: none;
  }

  .o-table__cell {
    font-size: var(--font-size-14);
    line-height: 1.4;
    padding: 5px 0 5px 0;
    width: 100%;
    display: flex;
    border-bottom: 1px solid var(--color-neutral-20);
  }

  .o-table__cell--header {
    font-size: var(--font-size-14);
    line-height: 1.4;
  }
}