.editdevice-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.editdevice-server-message-div {
    display: flex;
    flex-direction: column;
}

.editdevice-root-div {
    display: flex;
    margin-top: 2%;
    width: 90vw;
    max-width: 540px;
}

.editdevice-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    margin-bottom: 5%;
}

.editdevice-title {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    text-align: left;
    font-size: clamp(24px, (3vh + 3vw)/2, 32px);
    font-weight: bold;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--primary-color);
    opacity: 1;
}

.editdevice-device-id-div {
    border-bottom: 2px solid #BFBFBF;
    padding-bottom: 35px
}

.editdevice-device-id-column {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.editdevice-device-id-row {
    display: flex;
    flex-grow: 1;
    min-height: max(min(max(2vh, 2vw), 19px), 8px);
    align-items: flex-end;
}

.editdevice-device-id-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin: 0 0 1% 0;
}

.editdevice-device-id-title {
    text-align: left;
    font-size: clamp(18px, (2.5vh + 2.5vw)/2, 24px);
    font-weight: 700;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--primary-color);
    opacity: 1;
}

.editdevice-device-id-textfield {
    
}

.editdevice-other-div {
    
}

.editdevice-other-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin: 5% 0 1% 0;
}

.editdevice-other-title {
    text-align: left;
    font-size: clamp(18px, (2.5vh + 2.5vw)/2, 24px);
    font-weight: 700;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--primary-color);
    opacity: 1;
}

.editdevice-device-tag-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin: 0 0 1% 0;
}

.editdevice-device-tag-title {
    text-align: left;
    font-size: clamp(16px, (2vh + 2vw)/2, 18px);
    font-weight: 500;
    font-family: 'Proxima Nova', sans-serif;
    opacity: 1;
}
.editdevice-input-div {
    width: 225px
}

.editdevice-device-tag-textfield {
    display: flex;
    width: 100%;
    margin-bottom: 4% !important;
    border-radius: 5px;
    border: 1px solid black;
    font-size: clamp(16px, (2vh + 2vw)/2, 18px);
    font-weight: normal;
    font-family: 'Proxima Nova', sans-serif;
    color: black;
    padding: 3px 3px 3px 15px;
    height: 30px;
}

.editdevice-device-tag-textfield:focus {
    border-color: var(--primary-color);
    border-width: 2px;
    outline: none;
}

.editdevice-device-tag-textfield:disabled {
    opacity: 0.4;
    pointer-events: none;
    background-color: rgba(191, 191, 191, 0.5);
    border: none;
}

.editdevice-device-tag-row {
    display: flex;
    align-items: flex-end;
    min-height: max(min(max(2vh, 2vw), 8px), 19px);
}

.editdevice-device-type-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin: 0 0 1% 0;
}

.editdevice-device-type-title {
    text-align: left;
    font-size: clamp(16px, (2vh + 2vw)/2, 18px);
    font-weight: 500;
    font-family: 'Proxima Nova', sans-serif;
    opacity: 1;
}

.editdevice-device-type-row {
    display: flex;
    align-items: flex-end;
    min-height: max(min(max(2vh, 2vw), 19px), 8px);
}

.editdevice-edit-element {
    display: flex;
    font-size: max(min(max(2vh, 2vw), 19px), 8px);
    font-weight: normal;
    font-family: 'Proxima Nova', sans-serif;
    color: #005BD4;
    margin-left: 10px;
    text-underline: #005BD4;
    text-decoration: underline;
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
}

.editdevice-edit-element:disabled {
    opacity: .3;
}

.editdevice-success-text {
    text-align: left;
    font-size: clamp(16px, (2vh + 2vw)/2, 18px);
    font-weight: normal;
    font-family: 'Proxima Nova', sans-serif;
    color: #009823;
    opacity: 1;
}

.editdevice-error-text {
    text-align: left;
    font-size: clamp(16px, (2vh + 2vw)/2, 18px);
    font-weight: normal;
    font-family: 'Proxima Nova', sans-serif;
    color: #FF0000;
    opacity: 1;
}

.editdevice-power-icon {
    margin-right: 10px;
    height: max(min(max(2vh, 2vw), 19px), 8px);
    width: max(min(max(2vh, 2vw), 19px), 8px);
}

.editdevice-info-icon {
    margin-left: 5px;
}

.editdevice-red-star {
    color: red;
}

.editdevice-save-button-div {
    display: flex;
    width: 100%;
    max-width: 800px;
    margin-top: 10px;
    justify-content: flex-start;
}

.editdevice-more-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 36px;
    border-top: 2px solid #BFBFBF;
}

.editdevice-more-title-div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin: 5% 0 1% 0;
}

.editdevice-more-title {
    text-align: left;   
    font-size: clamp(18px, (2.5vh + 2.5vw)/2, 24px);
    font-weight: 700;
    font-family: 'Proxima Nova', sans-serif;
    color: var(--primary-color);
    opacity: 1;
}

.editdevice-status-div {
    display: flex;
    background-color: #EEEEEE;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    padding: 10px;
}
.editdevice-status-text {
    font-size: clamp(6px, (.8vh + .8vw)/2, 16px);
}

.editdevice-status-highlighted-text {
    text-align: center;
    cursor: default;
    padding: 3px 10px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 15px;
    color: #808080;
    background-color: #E0E0E0;
    border: 1px solid #808080;
    font-size: clamp(6px, (.8vh + .8vw)/2, 16px);
}
