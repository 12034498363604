.toggle {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 25px;
    overflow: hidden;
    margin: 0 10px 0 10px;
    height: 40px;
    cursor: pointer;
}

.toggle-option {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 25px;
    color: grey;
}

.toggle-option.active {
    background-color: var(--primary-color);
    color: white;
}
