/* Login Body */
.login-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: clamp(250px, (62vw + 62vh)/2, 840px);
  width: clamp(250px, (45vw + 45vh)/2, 700px);
  margin-top: 10vh;
  border-radius: 19px;
  background-color: #ffffff;
  box-shadow: 0px 6px 20px #0000008C;
}

@media screen and (max-width: 1279px){
  .login-body {
    margin-top: 16px;
  }
}

/* Form Title */
.form-title {
  width: 100%;
  z-index: 1;
  font-weight: 550;
}

/* Form Errors */
.form-errors {
  color: RED;
  z-index: 1;
  font-size: min(1.75vw, 1.75vh);
}

/* Form */
.form {
  display: flex;
  border-radius: 10px;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* Input Root */
.input-root {
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  height: 100%;
  width: 90%;
}

.reset-input-form-control {
  display: inherit;
  margin-top: 5%;
  align-items: center;
  justify-content: center;
  height: 15%;
  max-height: 60px;
  width: 100%;
}

/* Input Text */
.input-text {
  font-size: clamp(10px, (2vh + 2vw)/2, 18px);
  margin: 5%;
  width: 100%;
  height: 100%;
  border-radius: 11px;
  background-color: #F0F0F0;
}

/* Help Text */
.reset-help-text {
  font-family: "Proxima Nova", sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: clamp(12px, (1.5vh + 1.5vw)/2, 24px);
  width: 100%;
}

.help-text-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 5% 0 5% 0;
}

.password-mismatch-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 5%;
}

.password-mismatch-text {
  font-size: clamp(8px, (1.3vh + 1.3vw)/2, 16px);
  font-family: 'Proxima Nova', serif;
  color: #C3483E;
  width: 100%;
}

.row-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wrap-div {
  flex-wrap: wrap;
}

.center-div {
  align-items: center;
  justify-content: center;
}

.reset-title {
  display: flex;
  font-size: clamp(10px, (2.4vh + 2.4vw)/2, 30px);
  font-weight: 550;
  text-align: center;
  font-family: 'Proxima Nova', sans-serif;
  color: black;
  justify-content: center;
}


