/* 
===============
  Breakpoints
===============
*/
$breakpoint-sm: 500px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;

$breakpoints: (
  "small": $breakpoint-sm,
  "medium": $breakpoint-md,
  "large": $breakpoint-lg,
  "xlarge": $breakpoint-xl
);

/*
===============
  Spacing
===============
*/
 :root {
  --space: 10px;
  --space-4xs: 1px;
  --space-3xs: 2px;
  --space-2xs: 5px;
  --space-xs: calc(var(--space) * 1); // 10px
  --space-sm: calc(var(--space) * 1.5); // 15px
  --space-md: calc(var(--space) * 2); // 20px
  --space-lg: calc(var(--space) * 2.5); // 25px
  --space-xl: calc(var(--space) * 3); // 30px (60px med+)
  --space-2xl: calc(var(--space) * 4); // 40px (80px med+)
  --space-3xl: calc(var(--space) * 5); // 50px (100px med+)
}

/* Medium Breakpoint */
@media screen and (min-width: $breakpoint-md) {
  :root {
    --space: 20px;
    --space-xs: calc(var(--space) / 2); // 10px
    --space-sm: calc(var(--space) - 5px); // 15px
    --space-md: calc(var(--space) * 1.5); // 30px
    --space-lg: calc(var(--space) * 2); // 40px
  }
}

/*
==============
  Max-Widths
==============
*/
:root {
  --max-width-sm: 640px;
  --max-width-md: 762px;
  --max-width-lg: 1015px;
  --max-width-xl: 1180px;
}

/*
==============
  Min-Widths
==============
*/
:root {
  --min-width-xs: 130px;
}

/*
==========
  Colors
==========
*/
:root {
  --color-neutral-00: #fff;
  --color-neutral-10: #f8f8f8;
  --color-neutral-20: #d9d9d9;
  --color-neutral-40: #a0a0a0;
  --color-neutral-50: #898989;
  --color-neutral-60: #707070;
  --color-neutral-70: #5f5f5f;
  --color-neutral-80: #444444;
  --color-neutral-90: #272727;
  --color-neutral-100: #000;

  --color-yellow-70: #d09001;
  --color-yellow-50: #fdbe31;
  --color-yellow-40: #fed26f;
  --color-yellow-20: #f5dba3;
  --color-blue-60: #20647f;
  --color-blue-50: #2c7E9f;
  --color-blue-20: #d5e5ec;
  --color-green-50: #00975b;
  --color-red-60: #9f3127;
  --color-red-50: #c2483e;
}

/*
==============
  Typography
==============
*/
:root {
  --font-family: 'Proxima Nova', 'Roboto', 'Helvetica', 'Arial' sans-serif;
  --font-size-12: .75rem;
  --font-size-14: .875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-21: 1.313rem;
  --font-size-24: 1.5rem;
  --font-size-28: 1.75rem;
  --font-size-32: 2rem;

  // Note: used this calculator: https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
  --responsive-size--xl: clamp(var(--font-size-28), calc(1.5484rem + 0.8602vw), var(--font-size-32));
  --responsive-size--l: clamp(var(--font-size-24), calc(1.2984rem + 0.8602vw), var(--font-size-28));
  --responsive-size--m: clamp(var(--font-size-21), calc(1.1622rem + 0.6434vw), var(--font-size-24));
  --responsive-size--s: clamp(var(--font-size-18), calc(0.9734rem + 0.6469vw), var(--font-size-21));
  --responsive-size--xs: clamp(var(--font-size-16), calc(0.8992rem + 0.4301vw), var(--font-size-18));

  --responsive-size--textlink--s: clamp(var(--font-size-12), calc(0.6000rem + 0.4301vw), var(--font-size-14));

  /* Responsive Line Heights */
  --responsive-lh-s: 1.4;
  --responsive-lh-m: 1.2;
  --responsive-lh-l: 1.3;

  /* Responsive Font Weights */
  --responsive-fw-bold-semi: 500;

  /* Medium Breakpoint */
  @media screen and (min-width: $breakpoint-md) {
    :root {
      --responsive-lh-s: 1.2;
      --responsive-lh-m: 1.3;
      --responsive-lh-l: 1.2;

      --responsive-fw-bold-semi: 700;
    }
  }

  /*
  ==============
    Animations
  ==============
  */
  :root {
    --transition-all: all 0.4s ease-out;
  }


  /*
  ================
    BASE IMPORTS
  ================
  */
  @import "reset";
  @import "breakpoints";
  @import "display";
  @import "text";
  @import "spacing";
  @import "layout";

}

