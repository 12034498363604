@import "../../../../css/design-system/main";
@import "../../../../css/design-system/breakpoints";

.bulk-employee-action__container {
    background-color: var(--color-neutral-20);
    margin: 1vh 0 5vh 0;
    padding: 10px 10px 30px 10px;
    border-radius: 15px;
    box-shadow: 7px 7px 6px #00000029;
    width: 100%;
    @include media-max("medium") {
        display: flex;
        flex-direction: column;
    }
}

.bulk-employee-action__form-input {
    margin: 0 10px 0 10px;
}