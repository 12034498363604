/* 
==========================
  Base Settings - Layout
==========================
*/
.o-main {
  display: block;
  width: 100%;
}

.l-wrap {
  padding-left: var(--space-lg);
  padding-right: var(--space-lg);
  margin-left: auto;
  margin-right: auto;
}

.l-container--lg  {
  max-width: var(--max-width-xl);
}

.l-container {
  max-width: var(--max-width-lg);
}

.l-container--md  {
  max-width: var(--max-width-md);
}

.l-container--sm {
  max-width: var(--max-width-sm);
}

.l-min-container-xs {
  min-width: var(--min-width-xs);
}

.o-banner {
  display: flex;
  width: 100%;
  border-style: solid;
  border-width: 2px 0 2px 0;
  justify-content: center;
  box-shadow: 0 4px 5px 0 #00000024;
}

.o-banner--md {
  padding: var(--space);
}

.o-banner--sm {
  padding: var(--space-3xs) var(--space) var(--space-3xs) var(--space);
}

.o-banner__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  max-width: var(--max-width-lg);
}

.o-banner--info {
  border-color: var(--color-blue-50);
  background-color: var(--color-blue-20);
}

.o-banner__text {
  font-size: var(--responsive-size--s);
  line-height: var(--responsive-size--s);
  font-weight: 400;
  color: var(--color-neutral-90);
}

.o-banner__text--accented {
  font-size: var(--font-size-14);
  line-height: var(--responsive-lh-s);
  font-weight: 700;
  color: var(--color-blue-50);
}

.o-banner__content--padded-top {
  display: flex;
  flex-direction: row;
  padding-top: calc(var(--space-3xs) + 2px);
  gap: 10px;
}

.o-banner__link-icon--container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.o-banner__link--text {
  font-size: var(--responsive-size--s);
  line-height: var(--responsive-size--s);
  color: var(--color-blue-60);
}

.o-banner__link-icon--text {
  font-size: var(--responsive-size--textlink--s);
  font-weight: 500;
  line-height: 14px;
  color: var(--color-blue-60);
}

.o-button {
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-out;
  text-decoration: none;
  white-space: nowrap;
  appearance: none;
  border: none;
  cursor: pointer;
}

.o-button--sm {
  min-width: 43px;
  font-size: var(--font-size-12);
  line-height: 1.1;
  border-radius: 5px;
  padding: var(--space-2xs) var(--space-sm);
  gap: var(--space-2xs);
  margin-right: var(--space-sm);
  @include media("medium") {
    min-width: 56px;
    font-size: var(--font-size-14);
  }
}

.o-button--md {
  min-width: 66px;
  font-size: var(--font-size-14);
  line-height: 1.1;
  font-weight: 700;
  border-radius: 5px;
  padding: var(--space-xs) var(--space-md);
  gap: var(--space-xs);
  margin-right: var(--space);
  @include media("medium") {
    min-width: 94px;
    font-size: var(--font-size-18);
  }
}

.o-button--lg {
  min-width: 74px;
  font-size: var(--font-size-18);
  line-height: 1.1;
  font-weight: 700;
  padding: var(--space-sm) var(--space-md);
  border-radius: 5px;
  gap: var(--space-xs);
  margin-right: var(--space);
  @include media("medium") {
    min-width: 105px;
    font-size: var(--font-size-24);
  }
}

.o-button__icon--sm {
  width: 16px;
  height: 16px;
}

.o-button__icon--md {
  width: 22px;
  height: 22px;
  @include media-max("small") {
    width: 16px;
    height: 16px;
  }
}

.o-button__icon--lg {
  width: 22px;
  height: 22px;
}

.o-button--primary--filled {
  background-color: var(--color-blue-50);
  color: var(--color-neutral-00);
  box-shadow: 0 4px 4px 0 #00000040;
}

.o-button--secondary--filled {
  background-color: var(--color-neutral-60);
  color: var(--color-neutral-00);
  box-shadow: 0 4px 4px 0 #00000040;
}

.o-button--tertiary--filled {
  background-color: var(--color-yellow-50);
  color: var(--color-neutral-100);
  box-shadow: 0 4px 4px 0 #00000040;
}

.o-button--danger--filled {
  background-color: var(--color-red-50);
  color: var(--color-neutral-00);
  box-shadow: 0 4px 4px 0 #00000040;
}

.o-button--primary--outlined {
  background-color: transparent;
  color: var(--color-blue-50);
  border: 2px solid var(--color-blue-50);
}

.o-button--secondary--outlined {
  background-color: transparent;
  color: var(--color-neutral-60);
  border: 2px solid var(--color-neutral-60);
}

.o-button--tertiary--outlined {
  background-color: transparent;
  color: var(--color-neutral-100);
  border: 2px solid var(--color-yellow-50);
}

.o-button--danger--outlined {
  background-color: transparent;
  color: var(--color-red-50);
  border: 2px solid var(--color-red-50);
}


.o-button--primary--filled:hover {
  background-color: var(--color-blue-60);
  box-shadow: none;
}

.o-button--secondary--filled:hover {
  background-color: var(--color-neutral-80);
  box-shadow: none;
}

.o-button--tertiary--filled:hover {
  background-color: var(--color-yellow-70);
  box-shadow: none;
}

.o-button--danger--filled:hover {
  background-color: var(--color-red-60);
  box-shadow: none;
}

.o-button--primary--outlined:hover {
  background-color: var(--color-blue-50);
  color: var(--color-neutral-00);
}

.o-button--secondary--outlined:hover {
  background-color: var(--color-neutral-60);
  color: var(--color-neutral-00);
}

.o-button--tertiary--outlined:hover {
  background-color: var(--color-yellow-50);
  color: var(--color-neutral-100);
}

.o-button--danger--outlined:hover {
  background-color: var(--color-red-50);
  color: var(--color-neutral-00);
}

.o-button--primary--filled:active {
  border: 2px solid var(--color-yellow-50);
  transition: none;
}

.o-button--secondary--filled:active {
  border: 2px solid var(--color-yellow-50);
  transition: none;
}

.o-button--tertiary--filled:active {
  border: 2px solid var(--color-blue-50);
  transition: none;
}

.o-button--danger--filled:active {
  border: 2px solid var(--color-yellow-50);
  transition: none;
}

.o-button--primary--outlined:active {
  outline: 2px solid var(--color-yellow-50);
  transition: none;
}

.o-button--secondary--outlined:active {
  outline: 2px solid var(--color-yellow-50);
  transition: none;
}

.o-button--tertiary--outlined:active {
  outline: 2px solid var(--color-blue-50);
  transition: none;
}

.o-button--danger--outlined:active {
  outline: 2px solid var(--color-yellow-50);
  transition: none;
}

.o-button--disabled {
  opacity: .5;
  cursor: default;
  pointer-events: none;
}

.o-button:hover {
  cursor: pointer;
}

.o-textlink {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-out;
  text-decoration: none;
  white-space: nowrap;
  appearance: none;
  cursor: pointer;
  border-radius: var(--space-2xs);
  padding: var(--space-3xs) var(--space-2xs) var(--space-3xs) var(--space-2xs);
  line-height: 1.1;
  font-weight: 500;
  border: 2px solid transparent;
  background-color: transparent;
}

.o-textlink--s {
  // need this for some reason the postcss parser does not like this in the font-size explicitly
  font-size: var(--responsive-size--textlink--s);
  gap: var(--space-2xs);
}

.o-textlink--m {
  // need this for some reason the postcss parser does not like this in the font-size explicitly
  font-size: var(--responsive-size--xs);
  gap: var(--space-xs);
}

.o-textlink--disabled {
  opacity: .5;
  cursor: default;
  pointer-events: none;
}

.o-textlink--primary {
  color: var(--color-blue-50);
}

.o-textlink--secondary {
  color: var(--color-neutral-60);
}

.o-textlink--primary:active {
  color: var(--color-blue-50);
  border: 2px solid var(--color-yellow-50);
  transition: none;
}

.o-textlink--secondary:active {
  color: var(--color-neutral-60);
  border: 2px solid var(--color-yellow-50);
  transition: none;
}

.o-textlink--primary:hover {
  color: var(--color-blue-60);
  cursor: pointer;
}

.o-textlink--secondary:hover {
  color: var(--color-neutral-80);
  cursor: pointer;
}

.o-input__error {
  border-color: var(--color-red-50) !important;
}

.o-textinput {
  display: flex;
  border: 2px solid var(--color-neutral-100);
  border-radius: var(--space-2xs);
  transition: all 0.4s ease-out;
  width: 100%;
}

.o-textinput.prefixed {
  border-radius: 0 5px 5px 0 !important;
  flex-grow: 1;
}

.o-textinput:active {
  outline: none; /* Remove default outline */
  border-color: var(--color-yellow-50);
}

.o-textinput:focus {
  outline: none; /* Remove default outline */
  border-color: var(--color-yellow-50);
}

.o-textinput:disabled {
  opacity: 0.3;
  border-color: var(--color-neutral-100) !important;
}

.o-textinput__prefix {
  display: flex;
  border-radius: 5px 0 0 5px;
  border-width: 2px 0 2px 2px;
  border-style: solid;
  border-color: var(--color-neutral-100);
  transition: all 0.4s ease-out;
  padding: var(--space-2xs) var(--space-xs) var(--space-2xs) var(--space-xs);
  color: var(--color-neutral-00);
  background-color: var(--color-neutral-100);
  min-width: 50px;
}

.o-textinput__prefix.disabled {
  opacity: 0.3;
}

.o-textinput__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
}

.o-textinput--sm {
  gap: var(--space-xs);
  padding: var(--space-2xs) var(--space-xs) var(--space-2xs) var(--space-xs);
  font-size: var(--font-size-16);
  line-height: 1.4;
}

.o-textinput__label--sm {
  font-size: var(--responsive-size--xs);
  line-height: 1.4;
  display: flex;
}

.o-textinput--md {
  @include media("medium") {
    gap: var(--space-sm);
    padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-sm);
  }
  gap: var(--space-xs);
  padding: var(--space-2xs) var(--space-xs) var(--space-2xs) var(--space-xs);
  font-size: var(--font-size-16);
  line-height: 1.4;
}

.o-textinput__label--md {
  font-size: var(--responsive-size--xs);
  line-height: 1.4;
  display: flex;
}

.o-textinput__error {
  display: flex;
  font-size: var(--font-size-14);
  line-height: 20px;
  gap: 10px;
  color: var(--color-red-50);
}

.o-textinput--filter__container {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
}

.o-textinput--filter {
  width: 100%;
  max-width: 70vw;
  gap: var(--space-sm);
  border: 2px solid var(--color-neutral-20);
  border-radius: var(--space-2xs);
  padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-sm);
  font-size: var(--font-size-14);
  line-height: 20px;
  transition: all 0.4s ease-out;
}

.o-textinput--filter:active {
  outline: none; /* Remove default outline */
  border-color: var(--color-yellow-50);
}

.o-textinput--filter:focus {
  outline: none; /* Remove default outline */
  border-color: var(--color-yellow-50);
}

.o-textinput--filter:disabled {
  opacity: 0.3;
  border-color: var(--color-neutral-100) !important;
}

.o-textinput--filter-label {
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 22px;
  display: flex;
}
.o-textinput--filter__icon-container {
  position: absolute;
  top: 20%;
  right: 10px;
  display: flex;
  align-items: center;
  gap: var(--space-xs);
  
  .u-icon-search {
    color: var(--color-blue-50);
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .u-icon-search.disabled {
    opacity: .5;
    color: var(--color-neutral-100);
    cursor: default;
  }
  
  .u-icon-close {
    cursor: pointer;
  }
}

.o-textinput--filter__clear {
  font-size: var(--font-size-12);
  font-weight: 500;
  line-height: 18px;
  color: var(--color-blue-50);
  cursor: pointer;
}

.o-textarea__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
  position: relative;
}

.o-text-area {
  display: flex;
  resize: none;
  gap: var(--space-sm);
  border: 2px solid var(--color-neutral-100);
  border-radius: var(--space-2xs);
  transition: all 0.4s ease-out;
}

.o-text-area:active {
  outline: none; /* Remove default outline */
  border-color: var(--color-yellow-50);
}

.o-text-area:focus {
  outline: none; /* Remove default outline */
  border-color: var(--color-yellow-50);
}

.o-text-area:disabled {
  opacity: 0.3;
  border-color: var(--color-neutral-100) !important;
}

.o-textarea--sm {
  padding: var(--space-2xs) var(--space-xs) var(--space-2xs) var(--space-xs);
  font-size: var(--font-size-16);
  line-height: 1.4;
}

.o-textarea__label--sm {
  font-size: var(--responsive-size--xs);
  line-height: 1.4;
  display: flex;
}

.o-textarea--md {
  @include media("medium") {
    padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-sm);
  }
  padding: var(--space-2xs) var(--space-xs) var(--space-2xs) var(--space-xs);
  font-size: var(--font-size-16);
  line-height: 1.4;
}

.o-textarea__label--md {
  font-size: var(--responsive-size--xs);
  line-height: 1.4;
}

.o-textarea__error {
  display: flex;
  font-size: var(--font-size-14);
  line-height: 20px;
  gap: 10px;
  color: var(--color-red-50);
}

.o-textarea__count {
  position: absolute;
  font-size: var(--font-size-14);
  bottom: 0;
  right: 5px;
}

.o-textarea__count.red {
  color: red;
}

.o-select__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
}

.o-select {
  position: relative;
  display: flex;
  gap: var(--space-sm);
  border: 2px solid var(--color-neutral-100);
  border-radius: var(--space-2xs);
  cursor: pointer;
  align-items: center;
  transition: all 0.4s ease-out;
}

.o-select--filled {
  background-color: var(--color-neutral-00);
}

.o-select--outlined {
  background-color: transparent;
}

.o-select--sm {
  padding: var(--space-2xs) var(--space-xs) var(--space-2xs) var(--space-xs);
  font-size: var(--font-size-16);
  line-height: 1.4;
}

//.o-select-sm:active {
//  outline: none; /* Remove default outline */
//  border-color: var(--color-yellow-50);
//}
//
//.o-select-sm:focus {
//  outline: none; /* Remove default outline */
//  border-color: var(--color-yellow-50);
//}

.o-select--disabled {
  opacity: 0.3;
  border-color: var(--color-neutral-100) !important;
  cursor: default !important;
}

.o-select__label--sm {
  font-size: var(--responsive-size--xs);
  line-height: 1.4;
  display: flex;
}

.o-select--md {
  @include media("medium") {
    padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-sm);
  }
  padding: var(--space-2xs) var(--space-xs) var(--space-2xs) var(--space-xs);
  font-size: var(--font-size-16);
  line-height: 1.4;
}

//.o-select-md:active {
//  outline: none; /* Remove default outline */
//  border-color: var(--color-yellow-50);
//}
//
//.o-select-md:focus {
//  outline: none; /* Remove default outline */
//  border-color: var(--color-yellow-50);
//}

.o-select__label--md {
  font-size: var(--responsive-size--xs);
  line-height: 1.4;
  display: flex;
}

.o-select__error {
  display: flex;
  font-size: var(--font-size-14);
  line-height: 20px;
  gap: 10px;
  color: var(--color-red-50);
}

.o-select__selected__option {
  display: flex;
  align-items: center;
  justify-content: unset;
  padding-right: var(--space);
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
}

.o-select__selected__option--sm {
  font-size: var(--font-size-16);
  line-height: 1.4;
  margin-right: 10px;
  gap: var(--space-2xs);
}

.o-select__selected__option--md {
  font-size: var(--font-size-16);
  line-height: 1.4;
  margin-right: 10px;
  gap: var(--space-xs);
}

.o-select__value--sm {
  color: var(--color-neutral-100);
  font-size: var(--font-size-14);
  line-height: 1.4;
  padding-right: var(--space);
}

.o-select__placeholder--sm {
  color: var(--color-neutral-60);
  font-size: var(--font-size-16);
  line-height: 1.4;
  padding-right: var(--space);
}

.o-select__placeholder--md {
  color: var(--color-neutral-60);
  font-size: var(--font-size-16);
  line-height: 1.4;
  padding-right: var(--space);
}

.o-select__value--md {
  color: var(--color-neutral-100);
  font-size: var(--font-size-16);
  line-height: 1.4;
  padding-right: var(--space);
}

.o-select__dropdown__menu {
  position: absolute;
  top: calc(100% + var(--space-2xs));
  left: -2px;
  right: 0;
  width: calc(100% + var(--space-2xs) - 1px);
  max-height: 0;
  padding: var(--space-xs) 0 var(--space-xs) 0;
  border: 2px solid var(--color-neutral-100);
  opacity: 0;
  border-radius: var(--space-2xs);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 1;
  overflow: scroll;
  overflow: -moz-scrollbars-none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  overflow-x: hidden;
  pointer-events: none;
  background-color: var(--color-neutral-00);
  transition: all 0.4s ease-in-out;
}

.o-select__dropdown__menu.active {
  max-height: 115px;
  opacity: 1;
  pointer-events: all;
}

.o-select__dropdown__menu::-webkit-scrollbar {
  display: none; /* For Webkit browsers */
}

.o-select__option {
  font-size: var(--font-size-14);
  line-height: 1.4;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 var(--space) 0 var(--space-sm);
}

.o-select__option:hover {
  background-color: var(--color-blue-20);
}

.o-select__dropdown__icon {
  position: absolute;
  right: 5px;
  width: 30px;
  height: 30px;
  transition: all 0.4s ease-out;
}

.o-select__dropdown__icon.open {
  transform: rotate(180deg);
}

.o-select--filter__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
}

.o-select--filter {
  background-color: var(--color-neutral-00);
  position: relative;
  display: flex;
  gap: var(--space-sm);
  border: 2px solid var(--color-neutral-20);
  border-radius: var(--space-2xs);
  padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-sm);
  font-size: var(--font-size-14);
  line-height: 20px;
  cursor: pointer;
  align-items: center;
  transition: all 0.4s ease-out;
}

.o-select--filter__label {
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 22px;
  display: flex;
}

.o-select--filter__selected__option {
  display: flex;
  align-items: center;
  justify-content: unset;
  font-size: var(--font-size-14);
  line-height: 20px;
  width: 90%;
}

.o-select--filter__value {
  color: var(--color-neutral-100);
  font-size: var(--font-size-14);
  line-height: 20px;
}

.o-select--filter__placeholder {
  color: var(--color-neutral-60);
  font-size: var(--font-size-14);
  line-height: 20px;
}

.o-select--filter__dropdown__menu {
  position: absolute;
  top: calc(100% + var(--space-2xs));
  width: calc(100% + var(--space-2xs) - 1px);
  left: -2px;
  right: 0;
  max-height: 0;
  padding: var(--space-xs) 0 var(--space-xs) 0;
  opacity: 0;
  border: 2px solid var(--color-neutral-20);
  border-radius: var(--space-2xs);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 1;
  overflow: scroll;
  overflow: -moz-scrollbars-none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  overflow-x: hidden;
  cursor: default;
  background-color: var(--color-neutral-00);
  pointer-events: none;
  transition: all 0.4s ease-in-out;
}

.o-select--filter__dropdown__menu.active {
  opacity: 1;
  max-height: 115px;
  pointer-events: all;
}

.o-select--filter__dropdown__menu::-webkit-scrollbar {
  display: none; /* For Webkit browsers */
}

.o-select--filter__dropdown__icon {
  position: absolute;
  color: var(--color-blue-50);
  right: 5px;
  width: 30px;
  height: 30px;
  transition: all 0.4s ease-out;
}

.o-select--filter__dropdown__icon.open {
  transform: rotate(180deg);
}

.o-select--filter__clear {
  font-size: var(--font-size-12);
  font-weight: 500;
  line-height: 18px;
  color: var(--color-blue-50);
  cursor: pointer;
}

.o-checkbox {
  display: inline-flex;
  margin-left: 6px;
  cursor: pointer;
  transition: all 0.4s ease-out;
}

.o-checkbox__disabled {
  opacity: 0.3;
  cursor: default;
}

.o-checkbox__error {
  font-size: var(--font-size-14);
  font-weight: 400;
  line-height: 20px;
  color: var(--color-red-50);
}

.o-checkbox__container {
  display: flex;
  gap: var(--space-xs);
}

.o-checkbox__label__container {
  display: flex;
  flex-direction: column;
  gap: var(--space-3xs);
  word-break: break-word;
}

.o-checkbox--sm input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid var(--color-blue-50);
  border-radius: var(--space-2xs);
  width: 16px;
  height: 16px;
  margin: var(--space-4xs) 0 0 0;
}

.o-checkbox--sm .checkmark {
  width: 16px;
  height: 16px;
  font-weight: 900;
  justify-content: center;
  background-color: var(--color-blue-50);
  color: #fff;
  border: 2px solid var(--color-blue-50);
  border-radius: var(--space-2xs);
  margin: var(--space-4xs) 0 0 0;
  position: absolute;
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}

.o-checkbox--sm.checked .checkmark {
  opacity: 1;
}
.o-checkbox__label--sm {
  font-size: var(--font-size-14);
  line-height: 1.4;
  display: flex;
}

.o-checkbox--md input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid var(--color-blue-50);
  border-radius: var(--space-2xs);
  padding: 3px 2px 3px 2px;
  width: 18px;
  height: 18px;
  margin: var(--space-3xs) 0 0 0;
}

.o-checkbox--md .checkmark {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  background-color: var(--color-blue-50);
  color: #fff;
  border: 2px solid var(--color-blue-50);
  border-radius: var(--space-2xs);
  margin: var(--space-3xs) 0 0 0;
  position: absolute;
  font-weight: 900;
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}

.o-checkbox--md.checked .checkmark {
  opacity: 1;
}

.o-checkbox__label--md {
  font-size: var(--font-size-16);
  line-height: 1.4;
  display: flex;
}

.o-checkbox--lg input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid var(--color-blue-50);
  border-radius: var(--space-2xs);
  @include media("medium") {
    width: 20px;
    height: 20px;
  }
  padding: 3px 2px 3px 2px;
  width: 18px;
  height: 18px;
  margin: var(--space-3xs) 0 0 0;
}

.o-checkbox--lg .checkmark {
  @include media("medium") {
    width: 20px;
    height: 20px;
  }
  width: 18px;
  height: 18px;
  font-weight: 900;
  position: absolute;
  display: flex;
  justify-content: center;
  background-color: var(--color-blue-50);
  color: #fff;
  border: 2px solid var(--color-blue-50);
  border-radius: var(--space-2xs);
  margin: var(--space-3xs) 0 0 0;
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}

.o-checkbox--lg.checked .checkmark {
  opacity: 1;
}

.o-checkbox__label--lg {
  font-size: var(--font-size-18);
  line-height: 1.4;
  display: flex;
}

.o-radio-button__container {
  display: flex;
  gap: var(--space-xs);
}

.o-radio-button__label__container {
  display: flex;
  flex-direction: column;
  gap: var(--space-3xs);
  word-break: break-word;
}

.o-radio-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-blue-50);
  border-radius: 52px;
  color: var(--color-neutral-00);
  cursor: pointer;
  transition: all 0.4s ease-out;
}

.o-radio-button__error {
  font-size: var(--font-size-14);
  font-weight: 400;
  line-height: 20px;
  color: var(--color-red-50);
}

.o-radio-button__option {
  background-color: var(--color-blue-50);
  border-radius: 50%;
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}

.o-radio-button__option.active {
  opacity: 1;
}

.o-radio-button--sm {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}

.o-radio-button__option--sm {
  width: 8px;
  height: 8px;
}

.o-radio-button__label--sm {
  font-size: var(--font-size-14);
  line-height: 1.4;
  display: flex;
}

.o-radio-button--md {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.o-radio-button__option--md {
  width: 10px;
  height: 10px;
}

.o-radio-button__label--md {
  font-size: var(--font-size-16);
  line-height: 1.4;
  display: flex;
}

.o-radio-button--lg {
  @include media("medium") {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.o-radio-button__option--lg {
  width: 10px;
  height: 10px;
}

.o-radio-button__label--lg {
  font-size: var(--font-size-18);
  line-height: 1.4;
  display: flex;
}

.o-radio-button--disabled {
  opacity: 0.3;
  cursor: default;
}

.o-form-error__container {
  color: var(--color-neutral-00);
  background-color: var(--color-red-50);
}

.o-form-error__container--md {
  @include media("medium") {
    padding: var(--space-sm) var(--space-lg) var(--space-sm) var(--space-lg);
  }
  padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-sm);
}

.o-form-error__message {
  text-align: center;
}

.o-form-error__message--md {
  font-size: var(--responsive-size--xs);
  line-height: 1.4;
}

.o-form-error__container--sm {
  padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-sm);
}

.o-form-error__message--sm {
  font-size: var(--font-size-16);
  line-height: 1.4;
}

.o-badge {
  border-radius: var(--space-2xs);
  border: 2px solid;
  padding: var(--space-2xs) var(--space-xs) var(--space-2xs) var(--space-xs);
  font-size: var(--font-size-12);
  line-height: 1.1;
  font-weight: 700;
  text-align: center;
}

.o-badge--on {
  color: var(--color-green-50);
  border-color: var(--color-green-50);
}

.o-badge--standby {
  color: var(--color-neutral-80);
  border-color: var(--color-neutral-80);
}

.o-badge--off {
  color: var(--color-neutral-40);
  border-color: var(--color-neutral-40);
}

.o-ruler {
  width: 100%;
  border: 1px solid var(--color-neutral-20);
  border-bottom-width: 0;
}

.o-accordion {
  position: relative;
  display: inline-block;
  width: 70%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.o-accordion__title {
  font-size: var(--font-size-24);
  line-height: 30px;
  font-weight: 700;
  margin-bottom: var(--space);
}

.o-accordion__section__header {
  border-bottom: 1px solid var(--color-neutral-100);
  padding: var(--space-xs) var(--space) var(--space-xs) var(--space);
  cursor: pointer;
  transition: all 0.4s ease-out;
  gap: var(--space);
}

.o-accordion__section__header.open {
  background-color: var(--color-yellow-40);
}

.o-accordion__section__header.closed {
  background-color: var(--color-yellow-50);
}

.o-accordion__section {
  width: 100%;
}

.o-accordion__section__title {
  font-weight: 700;
  color: var(--color-neutral-100);
  font-size: var(--font-size-18);
  line-height: 1.4;
  @include media-max("medium") {
    font-size: var(--font-size-16);
    line-height: 22px;
  }
}

.o-accordion__section__icon {
  width: 22px;
  height: 22px;
  transition: all 0.4s ease-out;
  @include media-max("small") {
    width: 16px;
    height: 16px;
  }
}

.o-accordion__section__icon.open {
  transform: rotate(180deg);
}

.o-accordion__section__content {
  height: 0;
  overflow: hidden;
  transition: height 0.4s;
}

.o-accordion__section__content--inner {
  padding: var(--space);
}

.o-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3vh;
}

.o-header__container {
  display: block;
  border-width: 0 0 1px 0;
  padding: var(--space-xs) 0 var(--space-xs) 0;
  background-color: var(--color-yellow-50);
  box-shadow: 0 4px 5px 0 #00000024;
}

.o-header__content{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.o-header__grid--3--center {
  display: flex;
  justify-content: center;
  padding: 0 var(--space-md) 0 var(--space-md);
  align-items: center;
  flex: 1;
}

.o-header__grid--3--left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.o-header__grid--3--right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.o-header__title{
  font-size: var(--responsive-size--s);
  line-height: var(--responsive-lh-m);
}

.o-header__circle {
  display: flex;
  justify-content: center;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  border: 2px solid black;
  color: black;
  text-align: center;
  background: #FFFFFF;
  @include media-max("small") {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.o-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3.6vh;
}

.o-footer__header {
  width: 100%;
  margin-bottom: var(--space-2xs);
}

.o-footer__header__container {
  gap: var(--space-2xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media("medium") {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.o-footer__header-text {
  display: flex;
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 18px;
  margin: unset;
  @include media-max("small") {
    font-size: var(--font-size-14);
    line-height: 16px;
  }
}

.o-footer__content {
  width: 100%;
  justify-content: center;
  background-color: var(--color-yellow-50);
}

.o-footer__content__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: unset;

  @include media("medium") {
    flex-direction: row;
    gap: var(--space-md);
  }
}

.o-footer__nav-text {
  color: var(--color-neutral-100);
  font-size: var(--font-size-18);
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  padding: var(--space-3xs) var(--space-2xs) var(--space-3xs) var(--space-2xs);
  border-bottom: 2px solid transparent;
  &:hover {
    color: var(--color-neutral-100);
    border-bottom: 2px solid var(--color-neutral-100);
  }
  &:active {
    border: 2px solid var(--color-neutral-100);
    border-radius: var(--space-2xs);
  }
  @include media-max("small") {
    font-size: var(--font-size-14);
    line-height: 20px;
    flex-direction: column;
    align-items: center;
    &:hover {
      border-bottom: 2px solid transparent;
    }
  }
}

.o-footer__nav__text.active {
  border-bottom: 2px solid var(--color-neutral-100);
}

.o-site-nav {
  width: 100%;
}

.o-site-nav__container {
  border-bottom: 2px solid var(--color-neutral-100);
}

.o-nav__button {
  white-space: nowrap;
  appearance: none;
  box-shadow: none;
  background: transparent none;
  outline-color: var(--color-neutral-100);
  color: var(--color-neutral-100);
  cursor: pointer;
  border: none;
  font-size: 2rem;
  padding: 0;
}

.o-nav__menu {
  position: absolute;
  padding: 25px;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: all 0.4s;
  z-index: 1000000;
  opacity: 0;
  pointer-events: none;
}

.o-nav__menu.open {
  top: 0;
  opacity: 1;
  pointer-events: all;
}

.o-nav__button--close {
  position: absolute;
  top: 25px;
  right: 25px;
}

.o-nav__section {
  margin: 0 0 30px 0;
}

.o-nav__section__title {
  font-size: var(--font-size-24);
  color: var(--color-neutral-100);
  margin-bottom: 10px;
  cursor: pointer;
}

.o-nav__section__title.active {
  font-size: var(--font-size-24);
  color: var(--color-blue-50);
  margin-bottom: 10px;
}

.o-nav__section__child {
  list-style: none;
  padding: 0;
}

.o-nav__section__child__label {
  margin-top: 10px;
  font-size: var(--font-size-18);
  color: var(--color-neutral-100);
  cursor: pointer;
}

.o-nav__section__child__label.active {
  color: var(--color-blue-50);
}