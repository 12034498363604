.Header-Table-Cell {
    font-weight: 500;
    padding: min(max(min(2vh, 2vw), 3px), 16px);
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max(min((12vh + 12vw)/2, 200px), 30px) !important;
    min-width: 100px;
    max-width: 300px;
    color: var(--primary-color);
}

.Header-Table-Content {
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: max(min((1.6vh + 1.6vw)/2, 24px), 10px);
    align-items: center;
    font-weight: bold;
}

.Centered-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Permission-Table-Cell {
    font-weight: 500;
    min-height: 70px;
    font-size: max(min((1.6vh + 1.6vw)/2, 24px), 10px);
    padding: min(max(min(2vh, 2vw), 3px), 16px);
    line-height: 1.43;
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Permission-Table-Row {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.Permission-Center-Table-Cell {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Top-Actions {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
}

.Top-Action {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px 10% 10px 0;
    color: black;
    text-transform: unset;
    font-weight: 500;
    text-decoration: none;
}

.permission-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.permission-container-indented {
    border-left: 1px solid;
    border-color: var(--primary-color);
    padding-left: 3vw;
}

.permission-section-header {
    font-weight: bold;
    font-size: min(max(1.5vw, 1em), 1.25em);
    margin-top: 40px;
}

.permission-input-label {
    font-size: min(max(1.5vw, 1em), 1.25em);
}

.permission-quantity-button {
    cursor: pointer;
    font-size: min(max(1.5vw, 1em), 1.25em);
    width: 15px;
    height: 15px;
    border: 1px solid black;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 5px;
}

.permission-quantity-button.disabled {
    opacity: .5;
}

.permission-quantity {
    width: 10px;
    text-align: center;
}

.permission-input {
    width: 20vw;
    max-width: 200px;
    min-width: 100px;
}

.permission-input-quantity {
    width: 10vw;
    max-width: 70px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.permission-select {
    min-height: 30px;
    border-radius: 5px;
}

.permission-view-only {
    background-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 15px;
}

.permission-toggle {
    width: 30vw;
    max-width: 350px;
    min-width: 200px;
}

.permission-input-text {
    width: 20vw;
    max-width: 250px;
    min-width: 200px;
    height: 30px;
    border: 1px solid;
    border-radius: 5px;
    padding-left: 5px;
}

.permission-forced-option {
    width: 20vw;
    max-width: 250px;
    min-width: 200px;
    height: 30px;
    border: 1px solid;
    border-radius: 5px;
    padding-left: 5px;
}

.summary-text {
    font-style: italic;
}

.devicetype-row {
    display: flex;
    gap: 5px;
    align-items: center;
}

@media (max-width:1200px) {
    .Top-Actions {
        width: 85vw;
        min-width: unset;
        max-width: unset;
        justify-content: center;
    }
}
@media (max-width:600px) {
    .Header-Table-Cell-Desktop-Only {
        display: none;
    }
    .Top-Action {
        margin: 10px 5% 10px 5%;
    }
}
