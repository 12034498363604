.o-dropdownlink {
  display: flex;
  align-items: center;
  position: relative;
  font-size: var(--font-size-14);
  line-height: 1.1;
  font-weight: 500;
  border: 1px solid transparent;
  padding: var(--space-2xs) var(--space-xs) var(--space-2xs) var(--space-xs);
  gap: var(--space-2xs);
  border-radius: var(--space-2xs);
}

.o-dropdownlink:active {
  border: 1px solid var(--color-yellow-50);
  gap: var(--space-2xs);
}

.o-dropdownlink:hover {
  background-color: var(--color-blue-20);
}

.o-dropdownlink--disabled {
  opacity: .5;
  cursor: default;
  pointer-events: none;
}

.o-dropdown__menu {
  display: block;
  flex-direction: column;
  flex-grow: 1;
  position: absolute;
  top: calc(100% + var(--space-2xs));
  left: auto;
  right: auto;
  min-width: 0; /* Ensure the width is dynamic based on content */
  width: max-content;
  opacity: 0;
  border-radius: var(--space-2xs);
  z-index: 1;
  pointer-events: none;
  background-color: var(--color-neutral-00);
  box-shadow: 0 4px 5px 0 #00000025;
  white-space: nowrap; /* Ensure items don't wrap and cause unnecessary width reduction */
  transition: all 0.4s ease-in-out;
}

.o-dropdown__menu.active {
  opacity: 1;
  pointer-events: all;
}
.o-dropdown__option__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--space-2xs) var(--space-sm) var(--space-2xs) var(--space-sm);
  width: 100%;
}

.o-dropdown__option__container.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: black;
  cursor: unset;
  padding: 0 var(--space-sm) 0 var(--space-sm);
  border-top-left-radius: var(--space-2xs);
  border-top-right-radius: var(--space-2xs);
}

.o-dropdown__option {
  font-size: var(--font-size-14);
  line-height: 1.4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--space-2xs);
  padding: var(--space-2xs) var(--space-2xs) var(--space-2xs) var(--space-2xs);
  width: 100%;
  box-sizing: border-box;
}

.o-dropdown__option.header {
  color: white;
  font-size: var(--font-size-18);
  cursor: unset;
  width: 100%;
}

.o-dropdown__option:hover {
  background-color: var(--color-blue-20);
}

.o-dropdown__option.header:hover {
  background-color: black;
}

.o-dropdown__icon {
  position: absolute;
  right: 5px;
  width: 30px;
  height: 30px;
}