.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #444444;
    height: clamp(800px, 100vh, 1600px);
}

.login-header {
  width: 100%;
  height: 30%;
  background: #FBBC41;
  border-radius: 19px 19px 0px 0px; 
}

.logo-typography {
  font-size:  clamp(8px, (2vw + 2vh)/2, 30px);
  font-weight: 550;
  text-align: center;
  font-family: 'Proxima Nova', sans-serif;
  color: black;
}

/* Logo */
.login-logo {
  width: clamp(20px, (7.5vw + 7.5vh)/2, 105px);
  max-width: 175px;
  height: clamp(15px, (5vw + 5vh)/2, 70px);
}

.login-logo-div-hover {
  :hover {
    cursor: pointer;
  }
}

.login-logo-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 200px;
  padding: 4%;
}

/* Login Body */
.login-page-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: clamp(250px, (45vw + 45vh)/2, 670px);
  width: clamp(250px, (45vw + 45vh)/2, 700px);
  margin-top: 10vh;
  margin-bottom: 5vh;
  border-radius: 19px;
  background-color: #ffffff;
  box-shadow: 0px 6px 20px #0000008C;
}

.forgot-password-page-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: clamp(250px, (45vw + 45vh)/1.75, 670px);
  width: clamp(250px, (45vw + 45vh)/2, 700px);
  margin-top: 10vh;
  margin-bottom: 5vh;
  border-radius: 19px;
  background-color: #ffffff;
  box-shadow: 0px 6px 20px #0000008C;
}

@media screen and (max-width: 1279px){
  .login-page-body, .forgot-password-page-body {
    margin-top: 16px;
  }

}

/* Login Text */
.login-text {
  font-size: min(1.85vw, 1.85vh);
  text-align: center;
  color: BLACK;
  position: relative;
}

/* Error Message */
.error-message {
  font-size: min(2.5vw, 2.5vh);
  text-align: center;
  font-weight: 550;
  position: relative;
  margin-top: 10%;
}

/* Environment Label */
.env-label {
  font-family: "Proxima Nova", sans-serif;
  font-size: min(2vw, 2vh);
  position: relative;
  text-align: center;
  font-weight: bold;
}

.tool-tip {
  width: 100%;
  height: 27px;
  text-align: center;
  font-family: "Proxima Nova", sans-serif;
  font-weight: normal;
  font-size: clamp(6px, (1.5vw + 1.5vh)/2, 18px);
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  margin-bottom: 10px;
}

.login-emergency-tool-tip {
  display: flex;
  justify-content: center;
  height: 27px;
  text-align: center;
  font-family: "Proxima Nova", sans-serif;
  font-weight: normal;
  font-size: clamp(6px, (1.6vh + 1.6vw)/2, 18px);
  color: #FFFFFF;
  opacity: 1;
}

.login-emergency-tool-tip-div-desktop {
  position: relative; 
  margin-top: 30px;
  margin-right: 5%;
  display: flex;
  width: 100%;
  justify-content: flex-end; 
  align-items: center;
  cursor: pointer;
}

.login-emergency-tool-tip-div-mobile {
  position: relative; 
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center; 
  align-items: center;
  cursor: pointer;
}

/* Help Div */
.help-div {
  width: 75%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: min(max(1.5vw, 1.2em), 1.2em);
  align-items: center;
}

@media (max-width: 600px) {
  .help-div {
    justify-content: flex-start;
    width: 100%;
    right: unset;
    left: 6vw;
  }
}

/* Help Text */
.login-help-text {
  font-size: min(max(1.5vw, .85em), 1.2em);
  margin-left: 6px;
}

/* Version */
.version {
  color: GREY;
  margin-top: 10px;
  font-size: min(max(1.5vw, .6em), .9em);
  text-align: center;
  position: relative;
}

/* Input Root */
.login-input-root {
  display: flex;
  flex-direction: column;
  padding-top: 2%;
  height: 100%
}

/* Input Icon */
.input-icon {
  height: 2%;
}

/* Input Text */
.login-input-text {
  font-size: min(max(1.5vw, .8em), 1.0em);
  margin: 5%;
  width: 80%;
  height: 100%;
  border-radius: 11px;
  background-color: #F0F0F0;
}

@media screen and (max-width: 600px){
  .login-input-text {
    font-size: calc(8px + 0.5vw);
  }
}


.input-text-props {
  height: 10%; 
  font-size: min(max(1.5vw, 1.1em), 1.5em); 
}

/* Forgot Label */
.forgot-label {
  display: flex;
  width: 60%;
  padding-left: 10%;
  margin-top: 4%;
  justify-content: normal;
  cursor: pointer;
  font-family: "Proxima Nova", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: clamp(8px, (1.8vh + 1.8vw)/2, 18px);
  color: #2E7E9F;
}

/* Row Form Control */
.login-row-form-control {
  display: inherit;
  margin-bottom: 5%;
  align-items: center;
  justify-content: center;
  height: 15%;
  max-height: 60px;
}

/* Help Text */
.login-help-text {
  text-align: center;
  font-size: min(max(1.5vw, 1.2em), 1.5em);
}

.login-button-div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 5% 10% 0 10%;
  height: 15%;
  position: relative;
}

.login-form {
  width: 100%;
  padding: 0 5% 0 5%;
  height: 100%
}

.login-snackbar {
  max-width: 750px;
  width: 80%;
  background-color: #FFC7C3;
  color: #C3483E;
  padding: min(1.8vh, 1.8vw);
  border-radius: 15px;
  border: 2px solid #C3483E;
  text-align: center;
  top: 24px !important;
  left: inherit !important;
  right: inherit !important;
  transform: inherit !important;
}

.div-login-snackbar {
  width: 100%; 
  display: flex; 
  justify-content: center;
}

.login-snackbar-div {
  width: 100%; 
  display: flex; 
  justify-content: center;
}

.login-snackbar-text {
  font-weight: bold; 
  margin-left: 10px; 
  font-size: min(2vh, 2vw);
  text-align: center;
}

.login-forgot-password-text-div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.login-terms-privacy-div {
  display: flex;
  text-align: center;
  width: 300px;
  align-items: baseline;
  justify-content: center;
  color: white;
}

.login-terms-text {
  width: 150px;
  height: 27px;
  text-align: start;
  font-family: "Proxima Nova", sans-serif;
  font-weight: normal;
  font-size: clamp(6px, (1.4vw + 1.4vh)/2, 16px);
  letter-spacing: 0px;
  color: white;
  opacity: 1;
}

.login-terms-text:hover {
  cursor: pointer;
}

.login-privacy-text {
  width: 150px;
  height: 27px;
  text-align: end;
  font-family: "Proxima Nova", sans-serif;
  font-weight: normal;
  font-size: clamp(6px, (1.4vw + 1.4vh)/2, 16px);
  letter-spacing: 0px;
  color: white;
  opacity: 1;
}

.login-privacy-text:hover {
  cursor: pointer;
}

.sso-unauthorized-container {
  background-color: #444444;
  width: 100vw;
  height: 100vh;
}

.sso-unauthorized-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.unauth-arc-logo {
  margin-top: 15%;
  margin-bottom: 10vh;
}

.sso-unauthorized-title {
  color: white;
  margin-bottom: 10vh;
  font-size: clamp(24px, (4vw + 4vh)/2, 48px);
}

.sso-unauthorized-subtitle {
  color: white;
  margin-bottom: 35vh;
  display: flex;
  font-size: clamp(12px, (1.7vw + 1.7vh)/2, 32px);
}

.sso-unauthorized-footer {
  float: bottom;
  color: white;
  margin-bottom: 5vh;
  font-size: clamp(8px, (1.6vw + 1.6vh)/2, 18px);
}

@media (max-width: 600px) {
  .sso-unauthorized-body {
    justify-content: flex-start;
  }
  .sso-unauthorized-title {
    margin-bottom: 7vh;
  }
  .sso-unauthorized-subtitle {
    margin-bottom: 10vh;
  }
  .unauth-arc-logo {
    margin-top: 5vh;
  }
}
