.change-password__button-div {
    display: flex;
    width: 100%;
    max-width: 800px;
    margin-top: 80px;
    justify-content: flex-start;
}

.change-password__instruction {
    font-size: var(--responsive-size--xs);
    font-style: italic;
    margin-top: 10px;
    width: 80vw;
    min-width: 200px;
    max-width: 700px;
}