.sectional-dropdown {
  position: relative;
  display: inline-block;
  width: 70%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.sectional-dropdown .dropdown-header {
  padding: 10px;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  border: 1px solid black;
  transition: border-radius 0s linear 300ms;
}

.sectional-dropdown.open .dropdown-header {
  padding: 10px;
  cursor: pointer;
  position: relative;
  border-radius: 5px 5px 0 0;
  border: 1px solid black;
  transition: border-radius 0s linear 0s;
}

.sectional-dropdown .dropdown-content {
  max-height: 0px;
  overflow: hidden;
  border-width: 0 1px 1px 1px;
  border-color: transparent;
  border-style: solid;
  border-radius: 0 0 5px 5px;
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1), border-color 300ms ease-in-out;
}

.sectional-dropdown.open .dropdown-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
  background-color: white;
  border-width: 0 1px 1px 1px;
  border-color: black;
  border-style: solid;
  border-radius: 0 0 5px 5px;
  opacity: 1;
  max-height: 2000px;
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  padding-bottom: 10px;
}

.sectional-dropdown .dropdown-section {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 10px;
  background-color: #f0f0f0;
  margin: 10px 10px 0 10px;
  border-radius: 5px;
}

.sectional-dropdown .section-title {
  font-size: clamp(18px, (2.5vw + 2.5vh)/2, 20px);
}

.sectional-dropdown .section-description {
  font-size: clamp(12px, (2vw + 2vh)/2, 14px);
}

.sectional-dropdown .arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}

.sectional-dropdown .arrow.up {
  transform: translateY(-50%) rotate(180deg);
}

.sectional-dropdown .bullet-list {
  display: flex;
  flex-grow: 1;
  height: 100%;
  margin: 0 0 0 20px;
  padding-left: 0;
}

.sectional-dropdown .bullet-list li {
  list-style-type: disc;
}

.sectional-dropdown .bullet-div {
  display: flex;
  flex-grow: 1;
  max-height: 400px;
}

.arrow {
  color: black;
  font-size: 20px;
}
