/* 
=========================
  Base Settings - Reset
=========================
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: size(1);
}

blockquote,
body,
div,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
iframe,
label,
legend,
li,
nav,
object,
ol,
p,
section,
table,
ul {
  //margin: 0;
  //padding: 0;
}

hr {
  width: 100%;
  border: 1px solid var(--color-neutral-20);
  border-bottom-width: 0;
}

article,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

address {
  font-style: normal;
}

img,
video,
object,
svg,
iframe {
  max-width: 100%;
  border: none;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
