@import "../../../src/css/design-system/main";
@import "../../../src/css/design-system/breakpoints";

.faq__header {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 24px;
    @include media-max("medium") {
        flex-direction: column;
    }
}

.faq__help {
    display: flex;
    flex-direction: column;
    width: fit-content;
    @include media-max("medium") {
        margin-top: 10px;
    }
}

.faq__help__contact-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: flex-start;
}

.faq__help__tool-location {
    width: max-content;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
    font-size: var(--font-size-16);
    color: var(--color-blue-50);
}

.faq__tips__title {
    margin-top: 10px;
    margin-bottom: 1vh;
    font-weight: 700;
    color: var(--color-yellow-50);
    font-size: var(--font-size-18);
}