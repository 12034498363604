.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.arc-logo {
  width: clamp(20px, (10vw + 10vh) / 2, 100px);
  height: clamp(15px, (7.5vw + 7.5vh) / 2, 75px);
  margin-right: 5%;
}

@media (max-width: 600px) {
  .hidden-on-mobile {
    display: none;
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-content__container {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-header {
  font-size: clamp(24px, (3vw + 3vh)/2, 32px);
  color: var(--primary-color);
  font-weight: 550;
  text-transform: none !important;
}

.section-header {
  font-size: clamp(18px, (2.5vw + 2.5vh)/2, 24px);
  font-weight: 550;
  text-transform: none !important;
}

.sub-header {
  font-size: clamp(16px, (2.2vw + 2.2vh)/2, 20px) !important;
  font-weight: 550 !important;
  text-transform: none !important;
}

.primary {
  color: var(--primary-color) !important;
}

.field-text {
  font-size: clamp(16px, (2vw + 2vh)/2, 18px);
  font-weight: 500;
}

.app-bar {
  background-color: var(--secondary-color) !important;
  background-size: cover;
  height: 90px;
}

.logo-div {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.logo-label {
  font-size: clamp(10px, (3vw + 3vh)/2, 35px) !important;
  font-weight: 500 !important;
  color: var(--info-color) !important;
}

.left-grid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.center-grid {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.center-grid-title {
  font-size: min(max(1.5vw, 1.2em), 1.5em) !important;
  font-weight: bold !important;
  color: #000;
  text-align: center;
}

.right-grid {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Center-Table-Cell {
  text-align: center;
  font-weight: 500;
  font-size: min(max(1.5vw, 1em), 1.25em);
  padding: 16px;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.Table-Cell {
  font-weight: 500;
  font-size: min(max(1.5vw, 1em), 1.25em);
  padding: 16px;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Link {
  color: #2E7E9F;
  margin: 0;
  padding: 0 5px 0 5px;
  cursor: pointer;
  font-size: min(max(1.5vw, 1em), 1em);
  font-weight: 500;
}

.required-star {
  color: var(--error-color);
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  margin-left: 6px;
  cursor: pointer;
}

.custom-checkbox input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid grey;
  width: 15px;
  height: 15px;
  margin: 0;
  padding: 0;
}

.custom-checkbox .checkmark {
  font-size: 10px;
  font-weight: 900;
  display: none;
}

.custom-checkbox.checked .checkmark {
  display: flex;
  justify-content: center;
  background-color: #2E7E9F;
  color: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
}

.Link:hover {
  text-decoration: underline;
}


.Banner-Success {
  background-color: #84e880;
  border: 3px solid #5f9e51;
  border-left: none;
  border-right: none;
}

.Banner-Info {
  background-color: #C9DBE9;
  border: 3px solid #517D9E;
  border-left: none;
  border-right: none;
}

.Banner-Warning {
  background-color: #e8df80;
  border: 3px solid #9e9851;
  border-left: none;
  border-right: none;
}

.Banner-Danger {
  background-color: #e88080;
  border: 3px solid #9e5151;
  border-left: none;
  border-right: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select-container {
  position: relative;
  width: 250px;
  border: 2px solid black;
}

.select-container:focus {
  border-color: var(--primary-color) !important;
  border-width: 2px !important;
  outline: none !important;
}

.select-container.disabled {
  opacity: .4;
  color: grey;
}

.dropdown {
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}

.dropdown.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select {
  position: relative;
  min-height: 30px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4px 0px 4px 8px;
}

.select:focus {
  border-color: var(--primary-color) !important;
  border-width: 2px !important;
  outline: none !important;
}

.select.disabled {
  cursor: unset;
}

.select.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.selected-option {
  display: flex;
  align-items: center;
  justify-content: unset;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-option:focus {
  border-color: var(--primary-color) !important;
  border-width: 2px !important;
  outline: none !important;
}

.option-icon {
  margin-left: 8px;
}

.option-icon-left {
  margin-right: 8px;
}

.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: scroll;
  max-height: 20vh;
  z-index: 1;
}

.dropdown-menu-full {
  position: absolute;
  min-width: 140px;
  top: 80%;
  left: -50%;
  right: 50%;
  background-color: white;
  box-shadow: 0px 0px 5px #666666;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  z-index: 1;
}

.option {
  cursor: pointer;
  display: flex;
  font-size: .9em;
  align-items: center;
  padding: 8px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.option:hover {
  background-color: #e8e8e8;
}

.placeholder {
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog-input-label {
  font-weight: bold;
  font-size: min(max(1.2vw, 1.1em), 1.1em);
}

.dialog-input {
  width: 60%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 5px;
  margin-bottom: 2vh;
}

.dropdown-placeholder {
  color: black;
  font-weight: bolder;
  font-size: 2em;
  padding-bottom: 50%;
}

/* Override browser-specific styling */
.color-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.no-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  overflow: scroll; /* Forces the scrollbar to be visible */
}

.no-scrollbar::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
  background-color: transparent; /* Hide the track background */
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background-color: gray; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Adjust the border radius as needed */
}

.custom-search-select {
  position: relative;
  cursor: pointer;
  margin-top: 5px;
  width: 15vw;
  min-width: 200px;
  max-width: 240px;
}

.custom-search-selected-option {
  display: flex;
  align-items: center;
  padding: 8px 0 8px 10px;
  font-size: clamp(16px, (2vh + 2vw)/2, 18px);
  background-color: #D6E4F7;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  z-index: 100;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow: scroll;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-dropdown-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  outline: none;
}

.search-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-dropdown li {
  padding: 10px;
  cursor: pointer;
}

.search-dropdown li:hover {
  background-color: #E0E0E0 !important;
}

.primary-action-button {
  background-color: var(--primary-color);
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  height: 56px;
  padding: 5px 64px 5px 64px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.2);
}
.primary-action-button:disabled {
  background-color: var(--disabled-primary);
  color: var(--disabled-font-primary);
  transform: none !important;
  box-shadow: none !important;
  cursor: unset !important;
  font-size: 18px !important;
}

.secondary-action-button {
  background-color: rgba(118, 118, 118, 1);
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  height: 56px;
  padding: 5px 64px 5px 64px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.2);
}

.secondary-action-button:disabled {
  background-color: var(--disabled-secondary);
  color: var(--disabled-font-secondary);
  transform: none !important;
  box-shadow: none !important;
  cursor: unset !important;
  font-size: 18px !important;
}

.primary-action-button-sm {
  background-color: var(--primary-color);
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 5px 16px 5px 16px;
  height: 32px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.2);
}

.primary-action-button-sm:disabled {
  background-color: var(--disabled-primary);
  color: var(--disabled-font-primary);
  transform: none !important;
  box-shadow: none !important;
  cursor: unset !important;
  font-size: 18px !important;
}


.secondary-action-button-sm {
  background-color: rgba(118, 118, 118, 1);
  color: rgba(256, 256, 256, 1);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 5px 16px 5px 16px;
  height: 32px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.2);
}

.secondary-action-button-sm:disabled {
  background-color: var(--disabled-secondary);
  color: var(--disabled-font-secondary);
  transform: none !important;
  box-shadow: none !important;
  cursor: unset !important;
  font-size: 18px !important;
}

.icon-button-sm {
  border: 2px solid inherit;
  padding: 16px;
  font-size: 18px;
  font-weight: 550;
  height: 32px;
  border-radius: 8px;
}

.icon-button-sm:disabled {
  opacity: .55;
}

.hover-button:hover {
  cursor: pointer;
  font-size: 20px;
  transform: scale(1.1);
  box-shadow: 0 6px 6px 6px rgba(0, 0, 0, 0.2);
}

.action-button-disabled {
  box-shadow: none;
  opacity: .8;
}

@media (max-width: 600px) {
  .Center-Table-Cell {
    font-size: 0.75em;
    width: 40px;
  }
  .Table-Cell {
    font-size: 0.75em;
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
  .Link {
    font-size: 0.75em;
  }
  .app-bar {
    height: 80px;
  }
  .dropdown-menu-full {
    min-width: 100px;
  }
  .center-grid-title {
    font-size: min(max(1.5vw, 0.85em), 1.1em);
  }
  .logo-label.hidden {
    display: none;
  }
  .select {
    padding: 5px;
  }
  .selected-option {
    justify-content: center;
  }
  .option {
    padding: 2px;
  }
  .option-icon {
    margin-left: 4px;
  }
}

@media (max-width: 1280px) {
  .center-grid {
    align-items: flex-end;
  }
}

.loading {
  
}

.loading-wrapper {
  position: relative; /* Ensures the :after is positioned relative to this wrapper */
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026";
  width: 0px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}
