.importlanding-container-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 64px;
}

.importlanding-image-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 64px;
    margin-right: 32px;
    width: 522px;
    height: 323px;
}

.importlanding-main-div {
    display: flex;
    flex-direction: column;
}

.importlanding-text {
    font-size: clamp(16px, (2vw + 2vh)/2, 18px);
}

.importuploadfile-root-div {
    display: flex;
    margin-top: 2%;
}

.importuploadfile-container-div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.importuploadfile-row-div {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.importuploadfile-help-text-circle {
    margin-right: 20px;
}

.importuploadfile-table {
    border: 1px solid grey;
    border-collapse: collapse;
}

.importuploadfile-table-header-row {
    background-color: lightgrey;
}

.importuploadfile-table-header-cell {
    border: 1px solid grey;
    font-size: clamp(16px, (2vw + 2vh)/2, 18px);
    font-weight: 500;
}

.importuploadfile-table-row {

}

.importuploadfile-table-row-cell {
    vertical-align: top;
    border: 1px solid grey;
    font-size: clamp(16px, (2vw + 2vh)/2, 18px);
    padding: 10px 10px 32px 10px;
}

.importuploadfile-table-bullet-cell {
    padding-inline-start: 20px;
    margin-block-start: 0px;
}

.importuploaddata-duplicate-div {
    display: flex;
    width: 400px;
    height: 150px;
    background-color: rgba(191, 191, 191, 0.2);
    border: 1px solid grey;
    padding: 10px 10px 10px 0;
}
